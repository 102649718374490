@use '../../styles/variables' as v;
.alerts{
    position: fixed;
    z-index: 101;
    bottom: .5rem;
    left: .5rem;
    display: flex;
    flex-flow: column;
    gap: .25rem;
}
.new-update-alert{
    width: 100%;
    background: v.$color-branch;
    color: v.$dark-text-base;
    position: relative;
    z-index: 98;
    &_body{
        display: flex;
        gap: .5rem;
        margin: 0 auto;
        align-items: center;
        max-width: 900px;
        padding: .25rem .5rem;
        font-size: 1rem;
        span{
            flex: 1;
        }
    }
    .IconButton{
        color: inherit !important;
        &:hover{
            transform: scale(1.1);
        }
    }
}
.alert{
    width: 100%;
    border-radius: 7px;
    min-width: 300px;
    display: flex;
    transition: 300ms;
    padding: .75rem .5rem;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    position: relative;
    .icon{
        display: flex;
        align-items: center;
    }
    .IconButton{
        position: absolute;
        right: 0;
        color: currentColor !important;
    }
    a{
        color: currentColor;
        text-decoration: underline;
    }
    &.pending,
    &.branch{
        &.filled{
            background: v.$color-branch;
            color: v.$dark-text-base;
            .IconButton:hover{
                background: v.$color-branch-light;
            }
        }
        &.outlined{
            background: v.$dark-bg-base;
            color: v.$color-branch;
            border: 1px solid currentColor;
        }
    }
    &.success{
        &.filled{
            background: v.$color-green;
            color: v.$dark-text-base;
            .IconButton:hover{
                background: v.$color-green-l;
            }
        }
        &.outlined{
            background: v.$dark-bg-base;
            color: v.$dark-text-positive;
            border: 1px solid currentColor;
        }
    }
    &.negative{
        &.filled{
            background: v.$color-red;
            color: v.$dark-text-base;
            .IconButton:hover{
                background: v.$color-red-d;
            }
        }
        &.outlined{
            background: v.$dark-bg-base;
            color: v.$dark-text-negative;
            border: 1px solid currentColor;
        }
    }
}
@media (max-width: 50em) {
    .new-update-alert{
        &_body{
            font-size: .85rem;
            padding: .5rem .5rem;
        }
    }
    .alerts{
        width: 100%;
        left: 0;
        bottom: 4.5rem;
        .alert{
            width: 95%;
            margin: 0 auto;
            min-width: 0;
        }
    }
}