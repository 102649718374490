/* @use '../../styles/variables' as v; */
@import '../../styles/theme-variables.scss';
@import '../../styles/performance.scss';
.genres-container{
    min-height: 50vh;
    .genres-title{
        width: max-content;
        margin: 1rem auto;
    }
    .genres-body{
        &_data{
            max-width: 1200px;
            margin: 0 auto;
            .genres-list{
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                .browse-card{
                    padding: .625rem;
                }
            }
        }
    }
}

@media (min-width: 30em) {
    .genres-container{
        .genres-body{
            &_data{
                .genres-list{
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
}

@media (min-width: 35.5em) {
    .genres-container{
        .genres-body{
            &_data{
                .genres-list{
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}

@media (min-width: 61.25em) {
    .genres-container{
        .genres-body{
            &_data{
                .genres-list{
                    grid-template-columns: repeat(6, 1fr);
                }
            }
        }
    }
}