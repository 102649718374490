@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.lazy-settings{
    display: flex;
    flex-flow: column;
    gap: .5rem;
    .MuiSkeleton-root {
        @include themify($themes){
            background: themed('bgHighlight');
        }
        &::after{
            opacity: .025;
            @include themify($themes){
                background: linear-gradient(
                    90deg,
                    transparent,
                    themed('bgBase'),
                    transparent
                ); 
            }
        }
    }
}

.header-button{
    position: sticky !important;
    top: 4rem;
    left: 0;
    z-index: 1;
    @include themify($themes){
        background: themed('bgBase');
    }
}
div.settings{
    &-layout{
        max-width: 900px;
        margin: 0 auto;
    }
    &-sidebar{
        position: sticky;
        top: calc(4rem + 48px);
        max-height: calc(100vh - 4rem - 48px);
        overflow-y: auto;
        padding: 1rem .5rem;
        min-width: 250px;
        padding-left: 1.5rem;
        transition: .2s ease all;
        @include themify($themes){
            border-right: 1px solid themed('decorativeSubdued');
        }
        h2{
            font-weight: 700;
            font-size: .875rem !important;
            font-family: v.$font-logo;
            margin-bottom: .75rem;
            margin-top: .5rem;
            text-transform: uppercase;
            @include themify($themes){
                color: themed('textSubdued');
            }
            &:nth-child(1){
                margin-top: 0;
            }
        }
        > div {
            gap: .5rem;
            margin-left: -.75rem;
            margin-bottom: .5rem;
        }
    }
    &-content{
        flex: 1;
        width: 100%;
        padding: 1rem;
        min-height: 50vh;
        position: relative;
        max-width: calc(900px - 250px);
        #settings-content{
            transition: .2s opacity;
            &.loading{
                opacity: .4;
                margin-top: -.75rem;
                pointer-events: none;
            }
            .sticky-buttons-container{
                background: brown;
                margin: -1rem;
                padding: 1rem;
                position: sticky;
                top: calc(4rem + 2.65rem);
                @include themify($themes){
                    background: themed('bgBase');
                }
            }
            .sticky-buttons{
                display: flex;
                gap: .5rem;
                position: relative;
                padding: .5rem;
                border-radius: 100px;
                overflow: hidden;
                @include themify($themes){
                    background: themed('bgHighlight');
                }
                .button{
                    flex: 1;
                    &:hover{
                        @include themify($themes){
                            background: themed('bgElevatedBase');
                        }
                    }
                }
                &::before{
                    content: "";
                    top: 100%;
                    border-radius: 100px;
                    width: calc(50% - .75rem);
                    height: calc(100% - 1rem);
                    background: v.$color-branch;
                    position: absolute;
                    transition: .2s ease all;
                }
                &.private{
                    .button.private{
                        background: transparent;
                        color: v.$dark-text-base;
                        &:hover{
                            background: v.$color-branch-dark;
                        }
                    }
                    &::before{
                        top: .5rem;
                        left: .5rem;
                    }
                }
                &.public{
                    .button.public{
                        background: transparent;
                        color: v.$dark-text-base;
                        &:hover{
                            background: v.$color-branch-dark;
                        }
                    }
                    &::before{
                        top: .5rem;
                        left: calc(50% + .25rem);
                    }
                }
            }
        }
        .profile-layout{
            .profile-section{
                margin-top: 1.5rem;
                padding: .5rem;
                border-radius: 10px;
                @include themify($themes){
                    background: themed('bgHighlight');
                }
                section{
                    padding: .5rem;
                    h5{
                        font-family: v.$font-branch;
                        font-size: .765rem;
                        @include themify($themes) {
                            color: themed('textSubdued');
                        }
                    }
                    span.data{
                        font-size: .9rem;
                    }
                }
            }
        }
        .settings-section{
            h3{
                letter-spacing: .5px;
                font-size: .875rem;
                font-weight: 700;
                text-transform: uppercase;
                font-family: v.$font-logo;
                margin-bottom: 1rem;
            }
            padding-bottom: 1rem;
            padding-top: 1rem;
            border-bottom: 1px solid transparent;
            @include themify($themes) {
                border-color: themed('decorativeSubdued');
            }
            &:nth-child(1){
                padding-top: 0;
            }
            &:nth-last-child(1){
                border-color: transparent;
            }
            .info-layout{
                gap: 1.25rem;
            }
            .info-body{
                display: flex;
                flex-flow: column;
                gap: .25rem;
                h4{
                    font-weight: 400;
                    font-size: 1rem;
                }
                span{
                    @include themify($themes){
                        color: themed('textSubdued');
                    }
                }
            }
        }
        .floating-button{
            position: fixed;
            right: 1rem;
            bottom: 5rem;
            z-index: 100;
            background: v.$color-branch;
            display: none;
            transition: .2s ease all;
            &.active{
                bottom: 1rem;
            }
            span {
                color: #FFF;
            }
        }
        .settings-sidebar-backdrop{
            position: fixed;
            inset: 0;
            background: rgba($color: #000000, $alpha: .5);
            z-index: 99;
            transition: .2s ease all;
            visibility: hidden;
            opacity: 0;
            &.open{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.settings-account{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    padding: 1rem .5rem 0 .5rem;
    width: 100%;
    .account-user{
        display: flex;
        gap: 1rem;
        padding: 1rem 0;
        @include themify($themes){
            border-bottom: 1px solid themed('decorativeSubdued');
        }
        .edit-icon{
            position: absolute;
            bottom: 0;
            right: 0;
            color: v.$dark-text-base;
            background: v.$dark-bg-upper-base !important;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            padding: .5rem;
            svg{
                font-size: 1.5rem;
            }
        }
        input{
            position: absolute;
            visibility: hidden;
        }
        .avatar{
            > div{
                background: transparent;
            }
        }
        .change-image-layout{
            width: 120px;
            height: 120px;
        }
        .data{
            display: flex;
            flex-flow: column;
            gap: .75rem;
            flex: 1;
            @include themify($themes){
                color: themed('textBase');
            }
            .text{
                .upper{
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    button{
                        margin-top: 0rem;
                        margin-left: -.7rem;
                    }
                }
                h2{
                    line-height: 1.25;
                    font-family: v.$font-branch;
                    font-size: 2rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
                .h2{
                    height: calc(2rem * 1);
                    width: 100%;
                    max-width: 300px;
                    @include themify($themes){
                        background: themed('bgHighlight');
                    }
                    margin-bottom: calc(2rem * .25);
                }
                span{
                    @include themify($themes){
                        color: themed('textSubdued');
                    }
                }
                .span{
                    display: block;
                    height: calc(1.5rem * 1);
                    width: 100%;
                    max-width: 150px;
                    @include themify($themes){
                        background: themed('bgHighlight');
                    }
                }
            }
        }
        .buttons{
            display: flex;
            gap: .5rem;
            div.button{
                width: 100px;
                height: calc(1.25rem * 1 + .25rem * 2);
                @include themify($themes){
                    background: themed('bgHighlight');
                }
            }
        }
    }
    .settings-zone{
        display: flex;
        .settings-navigation{
            display: flex;
            flex-flow: column;
            max-width: 275px;
            width: 100%;
            position: sticky;
            height: max-content;
            top: 4rem;
            .section{
                width: 100%;
                display: flex;
                flex-flow: column;
                h3{
                    margin: .5rem 0;
                }
            }
        }
        .settings-content{
            display: flex;
            flex-flow: column;
            overflow: hidden;
            position: relative;
            flex: 1;
            @include themify($themes){
                border-left: 1px solid themed('decorativeSubdued');
            }
            &-layout{
                width: 100%;
                height: 100%;
            }
            .settings-home{
                display: flex;
            }
            .settings-title{
                padding: .5rem;
                justify-content: flex-start;
                position: sticky;
                top: 0;
                z-index: 2;
                @include themify($themes){
                    color: themed('textBase');
                    border-bottom: 1px solid themed('decorativeSubdued');
                    background: themed('bgBase');
                }
                &:hover{
                    @include themify($themes){
                        background: themed('bgHighlight');
                    }
                }
                * {
                    font-size: 1.17rem;
                    font-weight: bold;
                }
            }
            .settings-section{
                padding: .5rem;
                display: flex;
                flex-flow: column;
                gap: .5rem;
                .section-title{
                    font-size: 1.1rem;
                    font-weight: bold;
                }
                > span{
                    @include themify($themes){
                        color: themed('textSubdued');
                    }
                }
            }
            > div{
                button,a{
                    width: 100%;
                }
            }
        }
    }
    .settings-message{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        gap: 1rem;
        min-height: 10rem;
        padding: 1rem;
        margin: .5rem;
        border-radius: 3px;
        @include themify($themes){
            background: themed('bgHighlight');
        }
    }
}

@media (max-width: 50em) {
    div.settings{
        &-sidebar{
            top: 0;
            left: calc(-100% - 5.5rem);
            padding: 1rem;
            width: calc(100% - 5.5rem);
            max-width: 300px;
            margin-left: 0;
            padding: 1.5rem .5rem .5rem 1rem;
            height: 100%;
            max-height: initial;
            position: fixed;
            z-index: 100;
            @include themify($themes){
                background: themed('bgBase');
            }
            &.open{
                left: 0;
            }
        }
        &-content{
            #settings-content{
                margin-top: 0;
            }
            .floating-button{
                display: flex;
            }
        }
    }
    .settings-account{
        .settings-zone{
            .settings-navigation{
                max-width: inherit;
                border: none !important;
            }
            .settings-content{
                position: fixed;
                top: 100%;
                left: 0;
                transition: .2s;
                z-index: 100;
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                @include themify($themes){
                    background: themed('bgBase');
                }
                .settings-title{
                    top: 0;
                    padding: 1rem .5rem;
                }
                &.opened{
                    top: 0;
                }
                .settings-home{
                    display: none;
                }
                &-layout{
                    .sticky-buttons{
                        top: 2.5rem;
                    }
                }
            }
        }
    }
}