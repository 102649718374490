@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.reproductor-layout{
    --max-width: 1100px;
    position: relative;
    &.lazy {
        .data-reproductor-layout{
            .data-content{
                &-title{
                    .upper{
                        display: flex;
                        gap: 1rem;
                        .title{
                            height: 2rem;
                            @include themify($themes){
                                background: themed('bgHighlight');
                            }
                        }
                        .options{
                            display: flex;
                            gap: .5rem;
                            .IconButton{
                                padding: 1rem;
                                border-radius: 100px;
                                @include themify($themes){
                                    background: themed('bgHighlight');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .video-layout{
        width: 100%;
        display: flex;
        justify-content: center;
        max-height: 10rem;
        min-height: 11.25rem;
        height: 56.25vw;
        position: relative;
        color-scheme: light;
        @include themify($themes){
            background: themed('bgHighlight');
        }
        .video-settings{
            position: absolute;
            inset: 0;
            .background{
                position: absolute;
                inset: 0;
                img{
                    filter: blur(2px);
                }
            }
            .layout{
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1rem;
                @include themify($themes){
                    background: rgba($color: themed('bgHighlight'), $alpha: .9);
                }
                .content{
                    max-width: 25rem;
                    width: 100%;
                    padding: 1rem;
                    border-radius: 1rem;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    @include themify($themes){
                        // border: 1px solid rgba($color: themed('textBase'), $alpha: .1);
                        // background: rgba($color: themed('bgBase'), $alpha: .3);
                    }
                    h4{
                        font-size: 1.25rem;
                        font-weight: 400;
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                    .buttons{
                        display: flex;
                        gap: 1rem;
                        .button{
                            width: 100%;
                            border-radius: .5rem;
                            flex-flow: column;
                            flex: 1;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.25rem;
                            @include themify($themes){
                                border: 1px solid rgba($color: themed('textBase'), $alpha: .1);
                            }
                            svg{
                                font-size: 2em;
                            }
                            h5{
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
        .reproductor{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            &.loading {
                .reproductor-msg{
                    &.loader{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            .reproductor-msg{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                &.loader{
                    opacity: 0;
                    visibility: hidden;
                    transition: .2s ease all;
                    @include themify($themes){
                        background: themed('bgHighlight');
                    }
                }
                .loading{
                    .progress-circular{
                        width: clamp(2.5rem, 6vmin, 10vmin) !important;
                        height: clamp(2.5rem, 6vmin, 10vmin) !important;
                    }
                }
                .reproductor-background{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: flex;
                        backdrop-filter: blur(4px);
                        background-color: #000000cc;
                    }
                }
                .msg{
                    position: relative;
                    display: flex;
                    flex-flow: column;
                    gap: 1rem;
                    .text{
                        text-align: center;
                        .icon{
                            font-size: 8vmin;
                            width: 8vmin;
                            height: 8vmin;
                            &.premium{
                                width: 5rem;
                                height: 5rem;
                                fill: v.$color-branch;
                            }
                        }
                        h3{
                            font-size: 4vmin;
                        }
                    }
                    .buttons{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: .5rem;
                        > span{
                            user-select: none;
                        }
                        .button{
                            padding: .5rem 1rem;
                            font-size: 1rem;
                            border-width: 2px;
                            font-weight: bold;
                        }
                    }
                }
            }
            .video{
                width: 100%;
                height: 100%;
            }
            .button-section{
                position: absolute;
                top: .5rem;
                left: .5rem;
                .button{
                    padding: 1rem;
                    border-radius: .5rem;
                    @include themify($themes){
                        background: themed('bgBase');
                    }
                    &:hover{
                        @include themify($themes){
                            background: themed('bgHighlight');
                        }
                    }
                }
                .button.help{
                    padding: .75rem;
                    border-radius: .25rem;
                    &::before{
                        top: calc(-0.3rem + 1px);
                        bottom: initial;
                        left: 1rem;
                        rotate: 180deg;
                    }
                }
            }
        }
    }
    .data-reproductor-layout{
        position: relative;
        margin: 0 auto;
        display: flex;
        max-width: var(--max-width);
        flex-flow: column-reverse;
        .data-content{
            padding: .25rem;
            flex: 1;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            min-height: 300px;
            &-title{
                display: flex;
                flex-flow: column;
                .upper{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: .5rem 0 -.5rem;
                    .title{
                        flex: 1;
                        display: flex;
                        margin-bottom: .5rem;
                        a{
                            display: flex;
                            color: v.$color-branch-light;
                            &:hover{
                                text-decoration: underline;
                            }
                            h1{
                                font-weight: 500;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                                font-size: 1rem;
                            }
                        }
                    }
                }
                h2{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    font-size: 1.5rem;
                    overflow: hidden;
                    font-weight: 500;
                    margin-bottom: .75rem;
                }
            }
            .options{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: -.75rem 0;
                .IconButton{
                    padding: .75rem;
                }
            }
            .selector-component{
                display: flex;
                flex-flow: column;
                gap: .5rem;
                margin-bottom: 1rem;
                .selector-title{
                    h3{
                        font-weight: 600;
                        @include themify($themes){
                            color: themed('textBase');
                        }
                    }
                }
                .selector{
                    display: flex;
                    flex-flow: column;
                    height: max-content;
                    margin: 0 -.5rem;
                    > div {
                        flex: 1;
                        margin-top: -1px;
                        &:nth-child(1){
                            margin: 0;
                        }
                    }
                    &-layout{
                        display: flex;
                        flex-flow: column;
                        flex: 1;
                        @include themify($themes){
                            border-top: 1px solid themed('decorativeSubdued');
                            border-bottom: 1px solid themed('decorativeSubdued');
                        }
                        &__header{
                            .button{
                                width: 100%;
                                justify-content: space-between;
                                padding: .75rem;
                                h4{
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    font-family: 'Montserrat', sans-serif;
                                }
                                svg{
                                    transition: transform .2s;
                                }
                            }
                            &.true{
                                .button{
                                    @include themify($themes){
                                        color: themed('textBase');
                                        background: themed('bgElevatedBase');
                                    }
                                }
                                svg{
                                    transform: rotate(90deg);
                                }
                            }
                        }
                        &__body{
                            display: flex;
                            flex-flow: column;
                            .button{
                                background: transparent;
                                width: 100%;
                                &.active{
                                    @include themify($themes){
                                    background: themed('bgHighlight');
                                }
                                }
                            }
                        }
                    }
                }
            }
        }
        .data-sidebar{
            padding: .25rem;
            width: 100%;
            margin-top: .15rem;
            display: flex;
            flex-flow: column;
            gap: .5rem;
            .change-episode-layout{
                display: flex;
                flex-flow: column;
                gap: .25rem;
            }
            .change-episode-body{
                display: flex;
                flex-flow: column;
                flex: 1;
                &-title{
                    text-transform: uppercase;
                    padding: 0 .25rem;
                    font-family: v.$font-branch;
                    font-weight: 600;
                    @include themify($themes){
                        color: themed('textBase');
                    }
                }
                .card{
                    display: flex;
                    flex: 1;
                    max-width: 100%;
                    > .link{
                        width: 100%;
                        padding: .3125rem;
                        &:hover{
                            @include themify($themes){
                                background: themed('bgElevatedBase');
                            }
                        }
                        .card-layout{
                            display: flex;
                            position: relative;
                            .thumbnail-layout{
                                --content-image-ar-priority-height: 1;
                                display: block;
                                flex: 0 0 auto;
                                height: 4rem;
                                position: relative;
                                .duration{
                                    position: absolute;
                                    right: .25rem;
                                    bottom: .25rem;
                                    padding: .25rem .5rem;
                                    font-size: .7rem;
                                    z-index: 1;
                                    @include themify($themes){
                                        background: themed('bgBase');
                                        color: themed('textBase');
                                    }
                                }
                                .watched{
                                    position: absolute;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: #000000a8;
                                    color: #FFF;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    svg{
                                        font-size: 5vmin;
                                    }
                                    &.partial{
                                        background: #00000085;
                                        svg{
                                            background: #000000a8;
                                            border-radius: 100px;
                                            padding: .25rem;
                                            font-size: 4vmin;
                                        }
                                        .border{
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            width: 100%;
                                            background: #000000a8;
                                            height: .4rem;
                                            &::before{
                                                content: "";
                                                display: block;
                                                width: 63%;
                                                height: 100%;
                                                background: v.$color-branch-light;
                                            }
                                        }
                                    }
                                }
                                .thumbnail-sized{
                                    position: relative;
                                    width: calc(100% * var(--content-image-ar-priority-width) / var(--content-image-ar-priority-width));
                                    height: calc(100% * var(--content-image-ar-priority-height) / var(--content-image-ar-priority-height));
                                    .thumbnail{
                                        --width: calc(100%*var(--content-image-ar-priority-width)/var(--content-image-ar-priority-width));
                                        --height: calc(100%*var(--content-image-ar-priority-height)/var(--content-image-ar-priority-height));
                                        height: var(--height);
                                        width: var(--width);
                                        aspect-ratio: 16 / 9;
                                        @include themify($themes){
                                            background: themed('bgElevatedBase');
                                        }
                                        img,
                                        .img{
                                            display: block;
                                            height: 100%;
                                            width: 100%;
                                            aspect-ratio: 16 / 9;
                                        }
                                    }
                                }
                            }
                            .data-layout{
                                display: flex;
                                align-items: center;
                                flex: 1;
                                min-width: 0;
                                position: relative;
                                .data-body{
                                    display: flex;
                                    flex: 1;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    min-height: 4.3125rem;
                                    min-width: 0;
                                    padding-bottom: .25rem;
                                    padding-left: .75rem;
                                    padding-top: .25rem;
                                    .episode-title{
                                        --max-lines: 1;
                                        display: -webkit-box;
                                        overflow: hidden;
                                        white-space: unset;
                                        -webkit-line-clamp: var(--max-lines);
                                        -webkit-box-orient: vertical;
                                        font-weight: normal;
                                        @include themify($themes){
                                            color: themed('textBase');
                                        }
                                        a{
                                            color: currentColor;
                                        }
                                    }
                                    .episode-footer{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-top: auto;
                                        position: relative;
                                        @include themify($themes){
                                            color: themed('textSubdued');
                                        }
                                        &-meta{
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 2;
                                            overflow: hidden;
                                            position: relative;
                                            white-space: unset;
                                            .tags{
                                                display: flex;
                                                align-items: center;
                                                gap: .3rem;
                                                .tag{
                                                    font-size: .875rem;
                                                    border-right: 1px solid currentColor;
                                                    padding-right: .3rem;
                                                    line-height: 1;
                                                    &:nth-last-child(1){
                                                        border-color: transparent;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-height: 25rem) {
    .reproductor-layout{
        .video-layout{
            max-height: calc(100vh - 20rem);
        }
    }
}

@media (max-width: 50em) {
    .reproductor-layout{
        .video-layout{
        }
    }
}

@media (min-width: 480px) {
    .reproductor-layout{
        .data-reproductor-layout{
            .data-sidebar{
                .change-episode-layout{
                    flex-flow: row-reverse;
                    .change-episode-body{
                        &:nth-child(1){
                            .change-episode-body-title{
                                text-align: right;
                            }
                        }
                        &:nth-last-child(1){
                            .change-episode-body-title{
                                text-align: left;
                            }
                        }
                        .card{
                            > .link {
                                .card-layout{
                                    .thumbnail-layout{
                                        height: 3.75rem;
                                    }
                                    .data-layout{
                                        .data-body{
                                            min-height: 3.75rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 660px) {
    .reproductor-layout{
        .data-reproductor-layout{
            .data-sidebar{
                .change-episode-layout{
                    .change-episode-body{
                        .card{
                            > .link {
                                .card-layout{
                                    .thumbnail-layout{
                                        height: 9vmin;
                                        .duration{
                                            font-size: 1.6vmin;
                                        }
                                    }
                                    .data-layout{
                                        .data-body{
                                            min-height: 9vmin;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 979px) {
    .reproductor-layout{
        .data-reproductor-layout{
            flex-flow: row;
            gap: 1rem;
            .data-content{
                .selector-component{
                    .selector{
                        margin: 0;
                        &-layout{
                            &__header{
                                .button{
                                    width: calc(100% + 1rem);
                                    padding: .5rem;
                                    margin: 0 -.5rem;
                                }
                            }
                        }
                    }
                }
            }
            .data-sidebar{
                max-width: 22.9375rem;
                padding-left: 1.5rem;
                margin-top: calc(.5rem + 10px);
                .change-episode-layout{
                    flex-flow: column;
                    gap: 1rem;
                    .change-episode-body{
                        gap: .25rem;
                        &-title{
                            padding: 00;
                            padding-bottom: .25rem;
                            text-align: left !important;
                        }
                        .card{
                            margin: -.3125rem;
                            > .link {
                                .card-layout{
                                    .thumbnail-layout{
                                        height: 5.3125rem;
                                        .duration{
                                            font-size: .875rem;
                                        }
                                    }
                                    .data-layout{
                                        .data-body{
                                            min-height: 4.3125rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-height: 530px) and (orientation: landscape) {
    .reproductor-layout{
        .video-layout{
            max-height: calc(100vh - 3rem - 6.5rem);
        }
    }
}