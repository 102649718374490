@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.anime-landing{
    --mg-top: 0;
    --max-width: 1100px;
    width: 100%;
    display: flex;
    flex-flow: column;
    position: relative;
    margin-top: 0;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .anime-title{
        text-shadow: 1px 1px 5px rgba(0,0,0,0.6);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        letter-spacing: .5px;
        font-size: 1.975rem;
        font-weight: 500;
        line-height: 1.3;
    }
    .mobile{
        margin-top: .5rem;
        .anime-title{
            font-size: 1.5rem;
            -webkit-line-clamp: 2;
        }
    }
    .sticky-handler{
        margin-top: calc(var(--mg-top) - var(--mg-top));
    }
    &-background{
        display: none;
        filter: brightness(0.5);
        z-index: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute !important;
        .background-body{
            aspect-ratio: 16 / 9;
            width: 100%;
        }
    }
    &-header{
        width: 100%;
        position: relative;
        z-index: 1;
        padding-top: 0;
        padding-bottom: .5rem;
        margin-top: 0;
        &::before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20%;
            @include themify($themes){
                background: themed('bgBase');
            }
        }
        .gradient-transition{
            position: absolute;
            width: 100%;
            aspect-ratio: 16 / 9;
            bottom: 0;
            @include themify($themes){
                background: themed('bgBase');
                background: linear-gradient(0deg, themed('bgBase') 0%, rgba(0,0,0,.3) 60%, transparent 100%);
            }
        }
        &-layout{
            display: flex;
            position: relative;
            padding: 0 ;
            &.not-found{
                .background{
                    display: flex !important;
                }
            }
            .body{
                display: flex;
                position: relative;
                max-width: var(--max-width);
                width: 100%;
                margin: 0 auto;
                gap: 1rem;
                .cover{
                    overflow: hidden;
                    transition: .2s;
                    cursor: pointer;
                    user-select: none;
                    @include themify($themes){
                        background: themed('bgElevatedBase');
                    }
                    &.tall{
                        width: 100%;
                        border-radius: 8px;
                        min-width: 100px;
                        max-width: 225px;
                        aspect-ratio: 333 / 500;
                        display: none;
                    }
                    &.wide{
                        display: flex;
                        aspect-ratio: 889 / 500;
                        width: 100%;
                    }
                    &.square{
                        width: 100%;
                        min-width: 100px;
                        border-radius: 5px;
                        max-width: 225px;
                        aspect-ratio: 1 / 1;
                    }
                    &:hover{
                        transform: scale(1.01) translateY(-5px);
                    }
                    img{
                        width: 100%;
                    }
                }
                .data{
                    display: none;
                    padding-bottom: 0;
                    flex-flow: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    width: 100%;
                    color: #FFF;
                    .h1{
                        width: 100%;
                        border-radius: 2px;
                        margin-bottom: .25rem;
                        height: 2.25rem;
                        @include themify($themes){
                            background: themed('bgElevatedBase');
                        }
                    }
                }
            }
        }
    }
    &-body{
        width: 100%;
        padding: 0 .5rem;
        z-index: 1;
        position: relative;
        @include themify($themes){
            background: themed('bgBase');
        }
        .data {
            display: flex;
        }
        &-layout{
            margin: 0 auto;
            max-width: var(--max-width);
            display: flex;
            flex-flow: column;
            gap: 1rem;
            &.not-found{
                .info{
                    gap: .25rem;
                    h3{
                        margin-bottom: 1rem;
                    }
                    .language{
                        display: grid;
                        gap: .5rem;
                        grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
                        grid-template-rows: max-content;
                    }
                }
            }
            .info{
                display: flex;
                flex-flow: column;
                gap: .5rem;
                h1{
                    display: none;
                }
                .more-info{
                    padding: 1rem 1rem .5rem;
                    border-radius: 10px;
                    text-align: left;
                    min-height: 5rem;
                    line-height: initial;
                    display: flex;
                    flex-flow: column;
                    gap: .5rem;
                    position: relative;
                    justify-content: flex-start;
                    @include themify($themes){
                        background: themed('bgHighlight');
                        color: themed('textSubdued') !important;
                    }
                    &.active{
                        padding: 1rem;
                        gap: .75rem;
                        @include themify($themes){
                            background: themed('bgHighlight');
                        }
                        p{
                            -webkit-line-clamp: inherit;
                        }
                    }
                    .tags{
                        display: flex;
                        width: 100%;
                        justify-content: flex-start;
                        align-items: center;
                        gap: .25rem;
                        .rating{
                            color: v.$dark-text-positive;
                            &.Rx,
                            &.R\+{
                                color: v.$dark-text-negative;
                            }
                            &.R-17\+{
                                color: v.$color-orange;
                            }
                        }
                        span{
                            font-size: .9rem;
                        }
                    }
                    p{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        font-size: 1rem;
                        white-space: pre-wrap;
                    }
                    .categories{
                        display: flex;
                        flex-flow: row wrap;
                        gap: .5rem;
                        .button{
                            padding: .25rem .625rem;
                        }
                    }
                    .info{
                        display: flex;
                        flex-flow: column;
                        gap: .5rem;
                        .section{
                            display: flex;
                            justify-content: space-between;
                            flex-flow: row wrap;
                            gap: .25rem;
                            h4{
                                @include themify($themes){
                                    color: themed('textBase');
                                }
                                font-weight: normal;
                            }
                            p{
                                flex: 1;
                                display: flex;
                                text-align: right;
                                flex-flow: row wrap;
                                justify-content: flex-end;
                            }
                        }
                    }
                    .show-more,
                    .show-less{
                        display: flex;
                        border-radius: 8px;
                        justify-content: center;
                        text-transform: uppercase;
                        @include themify($themes){
                            color: themed('textBase');
                        }
                    }
                    .show-less{
                        @include themify($themes){
                            background: themed('bgElevatedBase');
                        }
                        &:hover{
                            @include themify($themes){
                                background: themed('bgElevatedHighlight');
                            }
                        }
                    }
                }
            }
            .episode-layout{
                display: flex;
                flex-flow: column;
                gap: 1.5rem;
                &-header{
                    display: flex;
                    gap: 1rem;
                    row-gap: .5rem;
                    flex-flow: column;
                    align-items: flex-end;
                    position: sticky;
                    top: 4rem;
                    z-index: 2;
                    padding-bottom: 1rem;
                    @include themify($themes){
                        background: themed('bgBase');
                        border-bottom: 1px solid themed('decorativeSubdued');
                    }
                    &.lazy{
                        .seasons,
                        .sort-episode{
                            width: 100%;
                            height: 2.5rem;
                            @include themify($themes){
                                background: themed('bgElevatedBase');
                            }
                        }
                        .sort-episode{
                            width: 30%;
                        }
                    }
                    .seasons{
                        width: 100%;
                        gap: .25rem;
                        display: flex;
                    }
                    .sort-episode{
                        position: relative;
                        .sort-icon{
                            position: absolute;
                            z-index: 1;
                            left: .5rem;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .select{
                            padding-left: 1.5rem;
                            width: max-content;
                        }
                    }
                }
                &-body{
                    &.lazy{
                        pointer-events: none;
                        .episode-list{
                            .card{
                                > .link{
                                    .card-layout{
                                        .thumbnail-layout{
                                            aspect-ratio: 16 / 9;
                                            @include themify($themes){
                                                background: themed('bgElevatedBase');
                                            }
                                        }
                                        .data-layout{
                                            .data-body{
                                                .episode-title{
                                                    width: 100%;
                                                    height: 1.5rem;
                                                    @include themify($themes){
                                                        background: themed('bgElevatedBase');
                                                    }
                                                }
                                                .episode-footer{
                                                    .tags{
                                                        width: 4rem;
                                                        height: .875rem;
                                                        @include themify($themes){
                                                            background: themed('bgElevatedBase');
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .episode-list{
                        display: flex;
                        flex-wrap: wrap;
                        margin: -.3125rem;
                        .card{
                            display: flex;
                            flex: 0 0 100%;
                            max-width: 100%;
                            > .link{
                                --padding: .3125rem;
                                --border-radius: .5rem;
                                --inner-border-radius: .3rem;
                                width: 100%;
                                padding: var(--padding);
                                border-radius: var(--border-radius);
                                border: 1px solid transparent;
                                &:hover{
                                    @include themify($themes){
                                        border-color: themed('decorativeSubdued');
                                        background: themed('bgHighlight');
                                    }
                                }
                                .card-layout{
                                    display: flex;
                                    width: 100%;
                                    position: relative;
                                    .thumbnail-layout{
                                        --content-image-ar-priority-height: 1;
                                        display: block;
                                        flex: 0 0 auto;
                                        border-radius: var(--inner-border-radius);
                                        overflow: hidden;
                                        height: 5.3125rem;
                                        aspect-ratio: 16 / 9;
                                        position: relative;
                                        .duration{
                                            position: absolute;
                                            right: .5rem;
                                            border-radius: 4px;
                                            bottom: .5rem;
                                            padding: .25rem .5rem;
                                            font-size: .875rem;
                                            z-index: 1;
                                            @include themify($themes){
                                                background: themed('bgBase');
                                                color: themed('textBase');
                                            }
                                        }
                                        .watched{
                                            position: absolute;
                                            top: 0;
                                            width: 100%;
                                            height: 100%;
                                            background: #000000a8;
                                            color: #FFF;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            svg{
                                                font-size: 10vmin;
                                            }
                                            &.partial{
                                                background: #00000085;
                                                svg{
                                                    background: #000000a8;
                                                    border-radius: 100px;
                                                    padding: .25rem;
                                                    font-size: 8vmin;
                                                }
                                                .border{
                                                    position: absolute;
                                                    bottom: 0;
                                                    left: 0;
                                                    width: 100%;
                                                    background: #000000a8;
                                                    height: .4rem;
                                                    &::before{
                                                        content: "";
                                                        display: block;
                                                        width: 63%;
                                                        height: 100%;
                                                        background: v.$color-branch-light;
                                                    }
                                                }
                                            }
                                        }
                                        .rating{
                                            position: absolute;
                                            top: .25rem;
                                            left: .25rem;
                                            padding: .25rem .5rem;
                                            font-size: .625rem;
                                            text-transform: uppercase;
                                            font-weight: 900;
                                            &.Rx{
                                                background: v.$color-red;
                                                color: v.$dark-text-base;
                                            }
                                            &.R\+{
                                                background: v.$color-red-d;
                                                color: v.$dark-text-base;
                                            }
                                            &.R-17\+{
                                                background: v.$color-orange;
                                                color: v.$light-text-base;
                                            }
                                        }
                                        .thumbnail-sized{
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                            .thumbnail{
                                                width: 100%;
                                                border-radius: 10px;
                                                height: 100%;
                                                @include themify($themes){
                                                    background: themed('bgElevatedBase');
                                                }
                                                img,
                                                .img{
                                                    display: block;
                                                    height: 100%;
                                                    width: 100%;
                                                    aspect-ratio: 16 / 9;
                                                    @include themify($themes){
                                                        background: themed('bgElevatedBase');
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .data-layout{
                                        display: flex;
                                        align-items: center;
                                        flex: 1;
                                        min-width: 0;
                                        position: relative;
                                        .data-body{
                                            display: flex;
                                            flex: 1;
                                            flex-direction: column;
                                            justify-content: space-between;
                                            min-height: 4.3125rem;
                                            min-width: 0;
                                            padding-bottom: .25rem;
                                            padding-left: .75rem;
                                            padding-top: .25rem;
                                            .episode-title{
                                                --max-lines: 1;
                                                display: -webkit-box;
                                                overflow: hidden;
                                                white-space: unset;
                                                -webkit-line-clamp: var(--max-lines);
                                                -webkit-box-orient: vertical;
                                                font-weight: normal;
                                                @include themify($themes){
                                                    color: themed('textBase');
                                                }
                                                a{
                                                    color: currentColor;
                                                }
                                            }
                                            .episode-footer{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: auto;
                                                position: relative;
                                                @include themify($themes){
                                                    color: themed('textSubdued');
                                                }
                                                &-meta{
                                                    display: -webkit-box;
                                                    -webkit-box-orient: vertical;
                                                    -webkit-line-clamp: 2;
                                                    overflow: hidden;
                                                    position: relative;
                                                    white-space: unset;
                                                    .tags{
                                                        display: flex;
                                                        align-items: center;
                                                        gap: .3rem;
                                                        .tag{
                                                            font-size: .875rem;
                                                            border-right: 1px solid currentColor;
                                                            padding-right: .3rem;
                                                            line-height: 1;
                                                            &:nth-last-child(1){
                                                                border-color: transparent;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .episode-msg{
                        display: flex;
                        flex-flow: column;
                        min-height: 10rem;
                        gap: 1rem;
                        justify-content: center;
                        align-items: center;
                        h2{
                            font-family: 'Montserrat', sans-serif;
                        }
                    }
                }
                &-footer{
                    display: flex;
                    justify-content: space-between;
                }
            }
            .music-videos{
                display: flex;
                flex-flow: column;
                gap: .5rem;
                h2{
                    font-size: 1.25rem;
                    font-weight: 600;
                }
                .videos-layout{
                    margin: 0 -.75rem;
                }
                .video-card{
                    display: flex;
                    flex-flow: column;
                    padding: 1rem;
                    width: 200px;
                    height: max-content;
                    .image-layout{
                        width: 100%;
                        aspect-ratio: 16 / 9;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include themify($themes){
                            background: themed('bgHighlight');
                        }
                        span{
                            display: flex !important;
                        }
                        img{
                            width: 100%;
                        }
                    }
                    .data-body{
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: space-between;
                        min-width: 0;
                        width: 100%;
                        text-align: start;
                        h3{
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            white-space: unset;
                            -webkit-box-orient: vertical;
                            font-weight: normal;
                            @include themify($themes){
                                color: themed('textBase');
                            }
                        }
                        .video-footer{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: auto;
                            position: relative;
                            h4{
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                                position: relative;
                                white-space: unset;
                                font-weight: normal;
                                font-size: .875rem;
                                line-height: 1;
                            }
                        }
                    }
                }
            }
            .user-actions{
                display: flex;
                flex-flow: column;
                gap: .5rem;
                margin: 0 -.5rem;
                .navigation-buttons{
                    .button.tabs{
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 35.5em) {
    .anime-landing{
        &-body{
            &-layout{
                .episode-layout{
                    &-header{
                        flex-flow: row;
                        justify-content: space-between;
                        .seasons{
                            max-width: 60%;
                            min-width: calc(50% - 1rem);
                            flex: 1;
                        }
                    }
                    &-body{
                        .episode-list{
                            margin: -.625rem;
                            .card{
                                flex: 0 0 50%;
                                max-width: 50%;
                                > .link{
                                    --inner-border-radius: .25rem;
                                    --padding: .625rem;
                                    .card-layout{
                                        display: block;
                                        height: 100%;
                                        width: 100%;
                                        .thumbnail-layout{
                                            --content-image-ar-priority-width: 1;
                                            --content-image-ar-priority-height: 0;
                                            height: auto;
                                        }
                                        .data-layout{
                                            .data-body{
                                                min-height: auto;
                                                padding-bottom: 0;
                                                padding-left: 0;
                                                padding-top: .75rem;
                                                .episode-title{
                                                    --max-lines: 2;
                                                    margin-bottom: .5rem;
                                                }
                                                .episode-footer{
                                                    &-meta{
                                                        -webkit-line-clamp: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 50em) {
    .anime-landing{
        margin-top: -4rem;
        &-background{
            display: initial;
        }
        &-header{
            padding-top: clamp(8rem, 10vw, 14rem);
            padding-bottom: 1.5rem;
            &-layout{
                padding: 0 1rem;
                .body{
                    .cover{
                        &.wide{
                            display: none;
                        }
                        &.tall{
                            display: flex;
                        }
                    }
                    .data{
                        display: flex;
                    }
                }
            }
        }
        &-body{
            padding-inline: 1rem;
            &-layout{
                .data {
                    display: none;
                }
                .episode-layout{
                    &-body{
                        .episode-list{
                            margin: -.8375rem;
                            .card{
                                flex: 0 0 33.33%;
                                max-width: 33.33%;
                                > .link{
                                    --inner-border-radius: .25rem;
                                    --border-radius: .4rem;
                                    --padding: .8375rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 64em) {
    .anime-landing{
        &-body{
            &-layout{
                .episode-layout{
                    &-header{
                        background: transparent;
                        padding: 0;
                        top: 0;
                        position: relative;
                        border: none !important;
                    }
                    &-body{
                        .episode-list{
                            .card{
                                flex: 0 0 25%;
                                max-width: 25%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .anime-landing{
        gap: .5rem;
        &-header{
            &-layout{
                padding: 0;
                .background{
                    display: none;
                }
                .body{
                    padding: 0;
                    .cover{
                        &.tall{
                            display: none;
                        }
                        &.square{
                            margin: .5rem auto;
                        }
                    }
                }
            }
        }
        &-body{
            &-layout{
                &.not-found{
                    .info{
                        h1, h3{
                            text-align: center;
                        }
                        .language{
                            flex-flow: column;
                        }
                    }
                }
                .info{
                    h1{
                        position: relative;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}