@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.IconButton,
.button{
    font-family: v.$font-primary !important;
    @include themify($themes){
        color: themed('textSubdued');
    }
    &:hover{
        @include themify($themes){
            color: themed('textBase');
        }
        .hover{
            visibility: visible !important;
            opacity: 1 !important;
        }
    }
}
button.IconButton,
a.IconButton{
    .change-image-layout{
        aspect-ratio: 1 / 1;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            position: absolute;
            pointer-events: none;
            border-radius: 100%;
            background: #00000093;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                font-size: 2.75rem;
                color: v.$dark-text-base;
            }
        }
        .hover{
            visibility: hidden;
            opacity: 0;
        }
        .avatar{
            width: 100%;
            height: 100%;
        }
    }
    &.square{
        border-radius: 2px;
    }
    &.outlined{
        @include themify($themes){
            color: themed('textSubdued');
        } 
        border: 2px solid currentColor !important;
    }

}
button.button,
a.button,
.button{
    text-transform: inherit;
    gap: .5rem;
    display: flex;
    padding: .5rem;
    border-radius: 0;
    position: relative;
    width: auto;
    &:disabled,
    &.Mui-disabled,
    &.disabled{
        pointer-events: initial;
        cursor: not-allowed;
        opacity: .5;
        color: currentColor;
    }
    .progress{
        padding: .3rem;
        position: absolute;
        .progress-circular{
            color: currentColor;
            width: 1rem !important;
            height: 1rem !important;
        }
    }
    .title{
        flex: 1;
        line-height: 1;
        font-weight: 400;
        font-size: 1em;
    }
    &.context-menu{
        border-radius: 0;
        padding: .75rem !important;
        justify-content: flex-start;
        @include themify($themes){
            border-bottom: 1px solid themed('decorativeSubdued');
        }
        &:nth-last-child(1){
            border-bottom: 0;
        }
        svg{
            font-size: 1.5em;
        }
        .title-layout{
            display: flex;
            align-items: center;
        }
        &:hover{
            @include themify($themes){
                background: themed('bgHighlight');
            }
        }
    }
    &.color-{
        &green{
            color: v.$dark-text-positive !important;
        }
    }
    &.link{
        font-size: .875rem;
        display: block !important;
        padding: .25rem .5rem !important;
        font-weight: 500 !important;
        width: max-content;
        border-radius: 100px;
    }
    
    &.avatar{
        padding: .25rem;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        @include themify($themes){
            background: themed('bgHighlight');
        } 
        &:hover{
            @include themify($themes){
                background: themed('bgElevatedBase');
            } 
            .banner{
                z-index: 0;
            }
        }
        .MuiAvatar-root{
            width: 100%;
            height: 100%;
        }
        .banner{
            position: absolute;
            z-index: -1;
            font-size: calc(1em / 4);
            padding: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            box-shadow: 0 0 10px v.$dark-bg-press;
            @include themify($themes){
                background: themed('bgBase');
            } 
            svg {
                font-size: 1em;
            }
        }
    }
    &.basic-button{
        min-width: 0;
        align-items: flex-start !important;
        &.no-desc{
            align-items: center !important;
            .body{
                line-height: 1;
            }
        }
        &.icon{
            .body{
                display: none;
            }
        }
        .icon{
            display: flex;
            font-size: 1em;
            color: currentColor;
            svg{
                width: 1em;
            }
        }
        &.spanflex{
            .body,
            span{
                flex: 1;
            }
        }
        &.high{
            padding: .5rem 1rem;
            gap: .25rem;
            &.hasIcon{
                padding-left: .7rem
            }
        }
        .body{
            display: flex;
            flex-flow: column;
            gap: .25rem;
            span{
                text-align: left;
                &.title{
                    font-size: 1rem;
                    line-height: 1.5;
                    @include themify($themes){
                        color: themed('textBase');
                    }
                }
                &.description{
                    line-height: 1.2;
                    @include themify($themes){
                        color: themed('textSubdued');
                    }
                }
            }
        }
    }
    &.type-{
        &warning{
            color: v.$dark-text-negative;
        }
    }
    &.variant-{
        &text{
            padding: 0 .5rem;
            border-radius: 100px;
            text-align: start;
            justify-content: flex-start;
            align-items: center !important;
            .icon{
                color: currentColor !important;
                font-size: 1rem;
                svg{
                    font-size: 1em;
                }
            }
            .body{
                span{
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        &secondary{
            @include themify($themes){
                background: themed('bgHighlight');
            }
        }
    }
    &.account-card{
        padding: 0;
        position: relative;
        &:hover{
            background: transparent !important;
        }
        &.w-25{
            max-width: 25%;
            width: 100%;
        }
        .close{
            position: absolute;
            left: 15%;
            top: 5%;
            padding: .125rem;
            z-index: 1;
            @include themify($themes){
                color: v.$light-text-base;
                background: v.$color-red;
            } 
            &:hover{
                background: v.$dark-text-negative;
            }
        }
        .button{
            flex-flow: column;
            width: 100%;
            border-radius: 10px;
            .svg{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                aspect-ratio: 1 / 1;
                font-size: 2rem;
                @include themify($themes){
                    background: themed('bgHighlight');
                } 
                svg{
                    width: 1em;
                    font-size: 1em;
                    height: 1em;
                }
            }
            .account-name{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
        }
        &.change-account{
            max-width: 500px;
            .accounts-cards{
                display: flex;
                padding: .5rem;
                margin-bottom: 1rem;
                .button{
                    flex-flow: column;
                    width: 100%;
                    padding: .5rem;
                    .account-profile{
                        width: 80%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        overflow: hidden;
                        aspect-ratio: 1 / 1;
                        @include themify($themes){
                            background: themed('bgHighlight');
                        } 
                        svg{
                            width: 70%;
                            height: 70%;
                        }
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.switch{
        &.compact{
            padding: .5rem 0;
            background: transparent;
            display: flex;
            flex-flow: column;
            .switch-clicker {
                display: flex;
                cursor: pointer;
                align-items: center;
                padding: .5rem 0;
                &:hover{
                    .switcher{
                        .dot{
                            @include themify($themes){
                                background: themed('bgUpperHighlight');
                            }
                        }
                    }
                }
                .title{
                    flex: 1;
                    font-weight: 500;
                    font-size: .9rem;
                    user-select: none;
                    @include themify($themes){
                        color: themed('textBase');
                    } 
                }
                .switcher{
                    width: 2.5rem;
                    aspect-ratio: 16 / 5;
                    border-radius: 100px;
                    position: relative;
                    padding: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include themify($themes){
                        background: themed('bgElevatedHighlight');
                    }
                    .dot{
                        left: 0;
                        aspect-ratio: 1 / 1;
                        height: 180%;
                        transition: .2s ease all;
                        position: absolute;
                        background: v.$color-branch;
                        border-radius: 100px;
                        @include themify($themes){
                            background: themed('bgUpperBase');
                        }
                    }
                }
            }
            .desc{
                font-size: .875rem;
                pointer-events: none;
                margin-top: -.5rem;
                @include themify($themes){
                    color: themed('textSubdued');
                }
            }
            &.active{
                background: transparent;
                .switch-clicker{
                    &:hover {
                        .switcher{
                            .dot{
                                background: v.$color-branch-light;
                            }
                        }
                    }
                    .switcher{
                        .dot{
                            background: v.$color-branch;
                            left: calc(100% - 1.25rem);
                        }
                    }
                }
            }
            &:hover{
                background: transparent;
            }
        }
    }
    &.select-option{
        display: flex;
        align-items: center;
        gap: .5rem;
        width: 100%;
        padding: .5rem;
        justify-content: flex-start;
        &.Mui-focused{
            @include themify($themes){
                background: themed('bgElevatedHighlight');
            }
        }
    }
    &.modal-button{
        flex: 1;
        border-radius: 100px;
        position: relative;
        overflow: hidden;
        font-size: 1rem;
        span.label {
            font-weight: 400;
            font-size: 1em;
        }
        .loading{
            background: inherit;
            position: absolute;
            inset: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .progress{
                width: 2em !important;
                height: 2em !important;
            }
        }
        &.outlined{
            @include themify($themes){
                border: 1px solid themed('decorativeSubdued');
            }
            
            &.type-{
                &warning{
                    &:hover{
                        color: v.$dark-text-base;
                        background: v.$color-red;
                    }
                }
            }
        }
        &.high{
            background: v.$color-branch;
            color: v.$dark-text-base;
            &:hover{
                background: v.$color-branch-dark;
            }
        }
    }
    &.profile-selector{
        flex-flow: column;
        border-radius: 5px;
        padding: 1rem;
        transition: none;
        gap: .75rem;
        .avatar{
            outline: 2px solid transparent;
            position: relative;
            .badge{
                position: absolute;
                bottom: -.5rem;
                right: -.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: .5rem;
                border-radius: 100%;
                scale: .8;
                @include themify($themes){
                    background: themed('bgElevatedHighlight');
                } 
            }
        }
        .svg{
            outline-color: currentColor;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                font-size: 3rem;
            }
        }
        .name{
            font-size: 1.35rem;
            letter-spacing: 1px;
            font-family: v.$font-logo;
            line-height: 1;
        }
        &:hover,
        &:focus{
            .avatar{
                outline-color: #FFF;
            }
        }
    }
    &.help{
        background: v.$color-branch !important;
        color: v.$dark-text-base;
        position: absolute;
        z-index: 1;
        &::before{
            content: "";
            position: absolute;
            bottom: calc(-.3rem + 1px);
            color: v.$color-branch;
            transform: rotate(45deg);
            border-top: .3rem solid transparent;
            border-left: .3rem solid transparent;
            border-bottom: .3rem solid currentColor;
            border-right: .3rem solid currentColor;
        }
        &:hover{
            opacity: .9;
        }
    }
    &.high{
        align-items: center !important;
        border-radius: 100px;
        background: v.$color-branch;
        color: v.$dark-text-base;
        padding: .5rem;
        &:hover{
            background: v.$color-branch-dark !important;
        }
        &.bigger{
            width: 100%;
            padding: .75rem;
        }
    }
    &.danger{
        background: v.$color-red-d;
        color: v.$dark-text-base;
        &:hover{
            background: v.$color-red !important;
        }
    }
    .premium{
        position: absolute;
        background: v.$color-branch;
        padding: .25rem .5rem;
        border-radius: 3px;
        top: -.5rem;
        right: -.5rem;
        z-index: 1;
        @include themify($themes){
            color: themed('textBase');
        }
    }
    .button-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            width: 1.5rem;
            fill: currentColor;
            height: 1.5rem;
            path{
                fill: currentColor;
            }
        }
    }
    .button-text{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        height: 1.5rem;
        font-size: 1em;
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            font-size: 1em;
            color: currentColor;
        }
    }
    &.highligh{
        border-radius: 3px;
        @include themify($themes){
            background: themed('bgElevatedBase');
        }
        &:hover{
            @include themify($themes){
                background: themed('bgElevatedHighlight');
            }
        }
    }
    &.full{
        &-width{
            width: 100%;
        }
        &-height{
            height: 100%;
        }
    }
    &.button-group{
        justify-content: center;
        padding: .75rem;
        span{
            font-size: 1em;
        }
    }
    &.profile{
        &-avatar,
        &-banner {
            padding: 0;
            overflow: hidden;
            transition: .2s ease all;
            img {
                transition: .1s ease all;
            }
            .hover{
                position: absolute;
                inset: 0;
                background: rgba(0,0,0,.5);
                display: flex;
                justify-content: center;
                align-items: center;
                visibility: hidden;
                opacity: 0;
                transition: .2s ease all;
            }
            &:hover {
                img{
                    transform: scale(1.05);
                }
                .hover{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-avatar{
            aspect-ratio: 1 / 1;
            border-radius: 100%;
            width: 5em;
            overflow: visible;
            transition: .2s ease all;
            font-size: 1.5rem;
            @include themify($themes) {
                background: themed('bgBase');
                outline: .125em solid themed('bgElevatedHighlight');
            }
            &:hover {
                @include themify($themes){
                    outline-color: themed('bgUpperBase');
                }
                .badge{
                    @include themify($themes){
                        background: themed('bgUpperBase');
                    }
                }
            }
            .hover{
                position: absolute;
                inset: 0;
                display: flex;
                border-radius: 100%;
                backdrop-filter: blur(2px);
                font-size: 1.5em;
                @include themify($themes) {
                    background: rgba($color: themed('bgBase'), $alpha: .5);
                }
                svg{
                    font-size: 1em;
                }
            }
            .badge{
                position: absolute;
                top: calc(5em - (2em / 5));
                left: calc(5em - (2em / 5));
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .75em;
                width: 2em;
                height: 2em;
                border-radius: 100%;
                transition: .2s ease all;
                z-index: 1;
                @include themify($themes){
                    background: themed('bgElevatedHighlight');
                } 
                svg{
                    font-size: 1em;
                }
            }
        }
        &-banner{
            aspect-ratio: 16 / 4.5;
            width: 100%;
            border-radius: 4px;
            &.div{
                @include themify($themes){
                    background: themed('bgElevatedHighlight');
                } 
            }
            .image{
                img{
                    width: auto !important;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
    &.no{
        &-padding{
            padding: 0;
        }
    }
    &.settings-sidebar{
        border-radius: 10px;
        padding: .5rem;
        font-size: .9rem;
        span, h3, h4{
            flex: 1;
            font-size: inherit;
            font-weight: 500;
        }
        &.active {
            @include themify($themes){
                color: themed('textBase');
                background: themed('bgElevatedBase');
            }
            &:hover{
                @include themify($themes){
                    background: themed('bgElevatedHighlight');
                }
            }
        }
    }
    &.tabs{
        padding: .25rem .5rem 0;
        &-2{
            padding: .5rem;
            @include themify($themes){
                background: themed('bgBase');
            }
            &.active{
                background: v.$color-branch !important;
                color: v.$dark-text-base;
            }
        }
        &.active{
            @include themify($themes){
                background: transparent;
                color: themed('textBase');
            }
            .text{
                border-color: currentColor;
            }
        }
        .text{
            padding-bottom: .25rem;
            border-bottom: 2px solid transparent;
        }
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.outline{
        @include themify($themes){
            border: 1px solid themed('decorativeSubdued');
        }
        &:hover{
            @include themify($themes){
                color: themed('textBase');
            }
        }
        &-highligh{
            font-size: 1rem;
            border: 1px solid currentColor;
            @include themify($themes){
                color: themed('textSubdued');
            }
            &:hover{@include themify($themes){
                color: themed('textBase');
            }
            }
        }
    }
    &.Mui-selected{
        @include themify($themes){
            color: themed('textBase');
            background: themed('bgElevatedHighlight') !important;
        }
        &:hover{
            @include themify($themes){
                background: themed('bgUpperBase') !important;
            }
        }
    }
    &.with-loading{
        @include themify($themes){
            background: themed('bgElevatedBase');
        }
        .button-text,
        .body,
        .icon{
            visibility: hidden;
            opacity: 0;
        }
    }
    &.right-arrow{
        .button-text{
            flex: 1;
        }
        .right-arrow{
            margin: auto;
            display: flex;
            align-items: center;
        }
    }
    &:hover{
        @include themify($themes){
            background: themed('bgElevatedBase');
        }
    }
    &.theme-selector{
        width: 3rem !important;
        aspect-ratio: 1 / 1;
        padding: 0;
        position: relative;
        border-radius: 100%;
        .theme-name {
            position: absolute;
            top: calc(100% + .5rem);
            font-size: .875rem;
            line-height: 1;
        }
        &.active{
            .preview{
                outline-color: v.$color-branch; 
            }
            .theme-name{
                @include themify($themes){
                    color: themed('textBase');
                }
            }
        }
        .preview {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            outline: 2px solid transparent;
            transition: .2s ease all;
            @include themify($themes){
                border: 1px solid themed('decorativeSubdued');
            }
            &:hover{
                @include themify($themes){
                    outline-color: themed('decorativeBase');
                }
            }
            &.sp{
                div {
                    height: 100%;
                    flex: 1;
                    &:nth-child(1){
                        background: v.$dark-bg-base;
                    }
                    &:nth-last-child(1){
                        background: v.$light-bg-base;
                    }
                }
            }
            &.light{
                background: v.$light-bg-base;
            }
            &.dark{
                background: v.$dark-bg-base;
            }
        }
    }
    &.toggle{
        .content{
            display: flex;
            gap: .5rem;
            flex: 1;
            .button-icon{
                align-items: flex-start;
            }
        }
        .toggle{
            width: 2.5rem;
            height: 1.25rem;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .2s ease;
            @include themify($themes){
                background: themed('bgUpperBase');
            }
        }
        &.active{
            @include themify($themes){
                background: themed('bgHighlight');
            }
            .toggle{
                background: v.$color-branch !important;
            }
        }
        &:hover{
            @include themify($themes){
                background: themed('bgElevatedBase');
            }
            .toggle{
                @include themify($themes){
                    background: themed('bgUpperHighlight');
                }
            }
        }
    }
    &.select{
        padding: .5rem;
        justify-content: space-between;
        .content{
            display: flex;
            gap: .5rem;
            align-items: center;
            .toggle{
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .2s ease;
                @include themify($themes){
                    background: themed('bgElevatedBase');
                }
                .circle{
                    width: 0;
                    aspect-ratio: 1 / 1;
                    background: v.$color-branch;
                    transition: .2s ;
                    border-radius: 100px;
                }
            }
        }
        .visual-help{
            display: flex;
            gap: .5rem;
            align-items: center;
            @include themify($themes){
                color: themed('textSubdued');
            }
            img{
                height: 1rem;
            }
        }
        &.active{
            @include themify($themes){
                background: themed('bgHighlight');
            }
            &:hover{
                @include themify($themes){
                    background: themed('bgElevatedBase');
                }
            }
            .toggle{
                .circle{
                    width: 100%;
                }
            }
        }
        &:hover{
            .toggle{
                @include themify($themes){
                    background: themed('bgUpperHighlight');
                }
            }
        }
    }
    &.active{
        @include themify($themes){
            background: themed('bgElevatedHighlight');
        }
    }
    &.big{
        padding: .75rem .5rem;
        font-size: 1.05rem;
        .button-icon{
            svg{
                width: 1.3rem;
                height: 1.3rem;
            }
        }
    }
    &.navigation{
        @include themify($themes){
            color: themed('textSubdued');
        }
        transition: .2s ease all;
        &:hover{
            @include themify($themes){
                color: themed('textBase');
            }
        }
        &.active{
            @include themify($themes){
                background: themed('bgHighlight');
            }
            color: v.$color-branch-light;
        }
    }
    &.description{
        align-items: flex-start !important;
        padding: .75rem;
        @include themify($themes){
            color: themed('textSubdued');
        }
        font-size: 1rem;
        .button-icon{
            @include themify($themes){
                color: themed('textBase');
            }
        }
        .button-text{
            span{
                justify-content: flex-start;
                &.title{
                    margin-top: -.15rem;
                    @include themify($themes){
                        color: themed('textBase');
                    }
                }
                &.description{
                    font-size: .8rem;
                    text-align: left;
                    line-height: 1.3;
                }
            }
        }
    }
    &.lang{
        background: transparent;
        width: 100%;
        border-radius: 10px;
        padding: .875rem;
        .content{
            flex: 1;
            padding: 0;
            .button-text{
                align-items: center;
                .title{
                    margin: 0;
                    line-height: 1;
                }
            }
        }
        .visual-help{
            span{
                line-height: 1;
            }
        }
    }
    &.theme {
        background: transparent;
        border-radius: 10px;
        margin: 0 -.5rem;
        padding: .5rem 0;
        .content{
            flex: 1;
        }
        .button-text{
            text-align: left;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1;
        }
    }
    &.left{
        justify-content: flex-start;
        .button-text{
            justify-content: flex-start;
            align-items: flex-start;
        }
        .title{
            text-align: left;
        }
    }
    &.special{
        background: v.$color-branch-dark;
        color: v.$dark-text-subdued;
        &:hover{
            color: v.$dark-text-base;
            background: v.$color-branch;
        }
    }
    &.size{
        &-small{
            padding: .25rem .5rem;
        }
        &-big{
            border-radius: .5rem;
            padding: .5rem;
            min-width: 100px;
        }
        &-bigger{
            padding: .75rem;
        }
    }
    &.highlight{
        @include themify($themes){
            background: themed('bgElevatedBase');
        }
        gap: .25rem;
        &-2{
            @include themify($themes){
                background: themed('decorativeBase');
                color: themed('textBaseInverted');
            }
            gap: .5rem;
            &:hover{
                @include themify($themes){
                    background: themed('decorativeHighlightPress');
                }
            }
        }
        .button-icon{
            svg{
                width: 1.25rem;
                height: 1.25rem;
            }
        }
        &:hover{
            @include themify($themes){
                background: themed('bgElevatedHighlight');
                color: themed('textBase');
            }
        }
    }
    &.secondary{
        @include themify($themes){
            background: themed('bgElevatedBase');
        }
        &.with-loading{
            .button-text{
                display: none;
            }
            .progress{
                position: relative;
            }
        }
        &:hover{
            @include themify($themes){
                background: themed('bgElevatedHighlight');
                color: themed('textBase');
            }
        }
    }
    &.rounded{
        border-radius: 100px;
    }
    &.special-2{
        color: v.$dark-text-base;
        background: v.$color-branch;
        padding: .6rem;
        font-weight: 400;
        &:hover{
            color: v.$dark-text-base;
            background: v.$color-branch-dark;
        }
        &.outline{
            background: transparent;
            border-color: v.$color-branch;
            color: v.$color-branch-light;
            &:hover{
                background: v.$color-branch;
            }
        }
    }
    &.btn-sidebar{
        justify-content: flex-start;
        &:hover{
            @include themify($themes){
                background: themed('bgElevatedHighlight');
                color: themed('textBase');
            }
        }
    }
    &.negative{
        color: v.$dark-text-negative !important;
        .button-text,
        .button-icon{
            color: currentColor !important;
            .title{
                color: currentColor !important;
            }
            .description{
                @include themify($themes){
                    color: themed('textSubdued');
                }  
            }
        }
    }
}
.buttons-group{
    display: flex;
    > button,
    > a{
        @include themify($themes){
            border: 1px solid themed('decorativeSubdued');
        }  
        &:nth-child(1){
            margin-right: -1px;
            border-radius: 3px 0 0 3px;
        }
        &:nth-last-child(1){
            border-radius: 0 3px 3px 0;
        }
    }
}
@media (min-width: 1024px){
    .IconButton{
        &:hover{
            @include themify($themes){
                background: themed('ButtonHoverBg');
            }       
        }
    }
}