@use '../styles/variables' as v;
@import '../styles/theme-variables.scss';
@import '../styles/performance.scss';
.no-hide{
    display: flex !important;
}
.header{
    width: 100%;
    z-index: 99;
    height: 4rem;
    padding: 0 .5rem;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .75s ease all;
    background-size:1px 8rem !important;
    background-position: 0 4rem !important;  
    @include themify($themes){    
        background: -webkit-linear-gradient(themed('bgBase') 50%, rgba(0,0,0,0) 100%);  
        background: -moz-linear-gradient(themed('bgBase') 50%, rgba(0,0,0,0) 100%);  
        background: -o-linear-gradient(themed('bgBase') 50%, rgba(0,0,0,0) 100%);  
        background: linear-gradient(themed('bgBase') 50%, rgba(0,0,0,0) 100%);  
    }
    &.visible{
        background-position: 0 6rem !important;  
        /* @include themify($themes){
            background: linear-gradient(180deg, themed('bgHeader') 90%, rgba(0,0,0,0) 100%);
        } */
    }
    &.page-{
        &settings{
            background-position: 0 8rem !important;  
        }
        &anime{
            background-position: 0 4.5rem !important;  
            &.visible{
                background-position: 0 8rem !important;  
            }
        }
        &search{
            position: sticky;
            background-size:1px 4rem !important;
            background-position: 0 4rem !important;  
            @include themify($themes){    
                background: linear-gradient(themed('bgBase') 0%, themed('bgHeader') 100%);  
            }
        }
    }
    .section{
        height: 100%;
        display: flex;
        align-items: center;
        .logo{
            display: flex;
            height: 3rem;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: .4rem;
                &:hover{
                    svg{
                        path{
                            @include themify($themes){
                                fill: themed('textBase');
                            }
                        }
                    }
                }
                svg{
                    height: 100%;
                    width: auto;
                    path{
                        @include performify($performancer){
                            transition: performed('transition');
                        }
                        @include themify($themes){
                            color: themed('ButtonText');
                        }
                    }
                }
            }
        }
        .special{
            height: 100%;
            border-radius: 0;
        }
        .nav,
        .options{
            height: 100%;
            display: flex;
            align-items: center;
            > a, > button{
                display: flex;
                align-items: center;
                justify-items: center;
                padding: .75rem;
                min-width: 0;
                font-size: 1rem;
                color: v.$dark-text-subdued;
                background: transparent;
                font-weight: 500;
                border-radius: 100px;
                transition: .2s ease all;
                cursor: pointer;
                @include themify($themes){
                    color: themed('textSubdued');
                }
                @include performify($performancer){
                    transition: performed('transition');
                }
                &:hover{
                    @include themify($themes){
                        background: themed('bgElevatedBase');
                        color: themed('textBase');
                    }
                }
                &.active{
                    @include themify($themes){
                        background: themed('bgHighlight');
                        color: themed('textBase');
                    }
                    .button-icon{
                        .filled{
                            display: flex;
                        }
                        .outlined{
                            display: none;
                        }
                    }
                }
                &.premium{
                    .button-text{
                        display: none;
                    }
                    @include themify($themes){
                        background: themed('bgElevatedBase');
                    }
                    &:hover{
                        background: v.$color-branch;
                        color: v.$dark-text-base;
                    }
                }
                .button-icon{
                    .filled{
                        display: none;
                    }
                }
            }
        }
        > .nav{
            display: none;
        }
    }
}
.header-menu{
    max-width: 400px;
    min-width: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
    @include themify($themes){
        background: themed('bgBase');
        border: 1px solid themed('decorativeSubdued');
    }
    &.genres{
        min-width: initial;
        max-width: initial;
        padding: 1rem 1rem .75rem;
        font-size: .9rem;
        .header-menu{
            &_title{
                text-transform: uppercase;
                font-family: v.$font-branch;
                font-weight: 600;
                font-size: 1em;
                @include themify($themes){
                    color: themed('textSubdued');
                } 
            }
            &_body{
                display: grid;
                grid-auto-columns: minmax(0,12.5rem);
                grid-auto-flow: column;
                grid-template-rows: repeat(5,1fr);
                margin: .5rem -.5rem;
                .button{
                    border-radius: 8px;
                    padding: .5rem;
                    .title{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        text-align: left;
                    }
                }
            }
        }
    }
    .section{
        --section-radius: .75rem;
        margin: .75rem;
        border-radius: calc(var(--section-radius) + .5rem / 2);
        display: flex;
        flex-flow: column;
        @include themify($themes){
            background: themed('bgHighlight');
            border: 1px solid themed('decorativeSubdued');
        }
        .button{
            border-radius: var(--section-radius) !important;
        }
        > div {
            width: 100%;
        }
    }
    button,a{
        width: 100%;
        justify-content: flex-start;
    }
    .no-logged{
        /* @include themify($themes){
            border-bottom: 1px solid themed('decorativeSubdued');
        } */
         padding: .5rem;
        .button{
            border-radius: .75rem;
        }
    }
    .sub-menu{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 100%;
        display: flex;
        flex-flow: column;
        transition: .2s;
        white-space: nowrap;
        &.active{
            left: 0;
        }
        @include themify($themes){
            background: themed('bgBase');
        }
        &__title{
            @include themify($themes){
                border-bottom: 1px solid themed('decorativeSubdued');
            }
        }
        .options{
            overflow-y: scroll;
            margin: 0 !important;
            .language{
                width: 100%;
                display: flex;
                flex-flow: column;
            }
        }
    }
    .user{
        display: flex;
        gap: .75rem;
        padding: 1rem;
        padding-bottom: 1.25rem !important;
        border-radius: 0 !important;
        margin-bottom: 2rem;
        @include themify($themes){
            color: themed('textSubdued');
            border-bottom: 1px solid themed('decorativeSubdued');
        }
        &:hover {
            @include themify($themes){
                background: themed('bgHighlight');
                color: themed('textBase');
            }
            .alt {
                @include themify($themes){
                    background: themed('bgHighlight');
                }
            }
            .info{
                @include themify($themes){
                    background: themed('bgElevatedHighlight');
                }
            }
        }
        .data{
            display: flex;
            flex-flow: column;
            flex: 1;
            justify-content: flex-start;
            overflow: hidden;
            .name,
            .username{
                line-height: 1.2;
                text-align: left;
                text-transform: initial;
                font-family: v.$font-primary;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
            .name{
                font-size: 1.25rem;
                max-width: 100%;
            }
            .username{
                font-size: .875rem;
            }
            .premium{
                display: flex;
                align-items: center;
                font-weight: bold;
                color: v.$color-branch-light;
                gap: .25rem;
                .icon{
                    display: flex;
                    align-items: center;
                    svg{
                        font-size: 1.3rem;
                        color: currentColor;
                        fill: currentColor;
                    }
                }
                .text{
                    font-family: 'Montserrat', sans-serif;
                }
            }
        }
        .info{
            display: flex;
            flex-flow: column;
            align-items: center;
            padding: .5rem;
            border-radius: 100px;
            @include themify($themes){
                background: themed('bgHighlight');
            }
        }
        .alt{
            position: absolute;
            bottom: -1rem;
            left: 50%;
            transform: translateX(-50%);
            padding: .25rem .5rem;
            border-radius: 100px;
            z-index: 1;
            @include themify($themes){
                background: themed('bgBase');
                color: themed('textBase');
                border: 1px solid themed('decorativeSubdued');
            }
        }
    }
    .nav{
        padding: .5rem;
        display: flex;
        flex-flow: column;
        .button{
            padding: .75rem;
            border-radius: .75rem;
        }
        > div{
            display: flex;
            flex-flow: column;
            width: 100%;
            padding: .5rem 0;
            @include themify($themes){
                border-bottom: 1px solid themed('decorativeSubdued');
            }
            &:nth-child(1){
                padding-top: 0;
            }
        }
    }
    .options{
        display: flex;
        flex-flow: column;
        margin: .25rem;
        .button-group{
            @include themify($themes){
                background: themed('bgHighlight');
            }
            &:hover{
                @include themify($themes){
                    background: themed('bgElevatedBase');
                }
            }
        }
    }
    .footer-link{
        display: flex;
        padding: .5rem;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        @include themify($themes){
            color: themed('textSubdued');
        }
    }
}
.sidebar{
    width: 300px;
    position: fixed;
    z-index: 99;
    top: 0;
    height: 100vh;
    left: -300px;
    transition: .2s ease all;
    display: flex;
    flex-flow: column;
    @include themify($themes){
        background: themed('bgBase');
        border-right: 1px solid themed('decorativeSubdued');
    }
    &.true{
        left: 0;
    }
    .top{
        height: 4rem;
        padding: .5rem;
        display: flex;
        gap: .5rem;
        justify-content: space-between;
        align-items: center;
        padding-bottom: .5rem;
        @include themify($themes){
            border-bottom: 1px solid themed('decorativeSubdued');
        }
        .logo{
            height: 1.5rem;
            svg{
                height: 100%;
            }
        }
    }
    nav{
        padding: .5rem;
        display: flex;
        flex-flow: column;
        gap: .5rem;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        flex: 1;
        >div{
            display: flex;
            flex-flow: column;
            gap: .5rem;
            padding-bottom: .5rem;
            @include themify($themes){
                border-bottom: 1px solid themed('decorativeSubdued');
            }
            &:nth-last-child(1){
                border-color: transparent;
            }
        }
    }
}
.footer{
    display: flex;
    flex-flow: column;
    gap: 1rem;
    padding: 4rem 1rem 2rem;
    position: relative;
    @include themify($themes){
        background: themed('bgBase');
        border-top: 1px solid themed('decorativeSubdued');
    }
    &-layout{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        row-gap: 2rem;
        padding-bottom: 2rem;
        .footer-section{
            flex: 1;
            min-width: 300px;
            max-width: 500px;
            h4{
                font-family: v.$font-branch;
                font-weight: 500;
            }
            ul{
                list-style: none;
                margin-top: .5rem;
                font-size: .9rem;
                display: flex;
                flex-flow: column;
                gap: .25rem;
                li{
                    a{
                        opacity: .8;
                        width: max-content;
                        margin-bottom: .25rem;
                        svg{
                            width: .9em;
                            height: .9em;
                            &.discord{
                                width: 1.3rem;
                                height: 1.3rem;
                            }
                        }
                        &:hover{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .brands{
        margin: 0 1rem;
        padding: 2rem 0 0;
        @include themify($themes){
            border-top: 1px solid themed('decorativeSubdued');
        }
        svg{
            height: 2rem;
            @include themify($themes){
                color: themed('textSubdued');
            }
            path{
                fill: currentColor;
            }
        }
    }
    .disclaimer{
        margin: 0 1rem;
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        row-gap: .25rem;
        font-size: .75rem;
        @include themify($themes){
            color: themed('textSubdued');
        }
    }
}

@media (max-width: 799px) {
    .header-menu{
        max-width: initial;
        width: 100%;
        min-width: initial;
        border: 0 !important;
        .sub-menu{
            left: 0;
            top: 100%;
            &.active{
                top: 0;
            }
        }
    }
}

@media (min-width: 30em) {
    .header{
        .section{
            .logo{
                a{
                    padding: .75rem;
                    padding-left: .25rem;
                }
            }
        }
    }
}

@media (min-width: 50em) {
    .header{
        justify-content: space-around;
        .section{
            > .nav {
                display: flex;
            }
        }
    }
}

@media (min-width: 60em) {
    .header{
        .section{
            .options{
                a,button{
                    &.premium{
                        .button-text{
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}