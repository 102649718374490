@use 'variables' as v;
$themes: (
    dark: (
        bgBase: v.$dark-bg-base,
        bgBaseTransparent: v.$dark-bg-base-tranparent,
        bgHeader: v.$dark-bg-header,
        bgHighlight: v.$dark-bg-highlight,
        bgPress: v.$dark-bg-press,
        bgElevatedBase: v.$dark-bg-elevated-base,
        bgElevatedHighlight: v.$dark-bg-elevated-highlight,
        bgElevatedPress: v.$dark-bg-elevated-press,
        bgUpperBase: v.$dark-bg-upper-base,
        bgUpperHighlight: v.$dark-bg-upper-highlight,
        bgUpperPress: v.$dark-bg-upper-press,
        textBaseInverted: v.$light-text-base,
        textBase: v.$dark-text-base,
        textSubdued: v.$dark-text-subdued,
        textHighlight: v.$dark-text-highlight,
        textBaseHighlight: v.$dark-text-base-highlight,
        textPositive: v.$dark-text-positive,
        decorativeBase: v.$dark-decorative-base,
        decorativeSubdued: v.$dark-decorative-subdued,
        decorativeHighlight: v.$dark-decorative-highlight,
        decorativeHighlightPress: v.$dark-decorative-highlight-press,
        decorativeBranch: v.$color-branch,
        adBackground: v.$color-yellow,
        adText: v.$color-black
    ),
    light: (
        bgBase: v.$light-bg-base,
        bgBaseTransparent: v.$light-bg-base-tranparent,
        bgHeader: v.$light-bg-header,
        bgHighlight: v.$light-bg-highlight,
        bgPress: v.$light-bg-press,
        bgElevatedBase: v.$light-bg-elevated-base,
        bgElevatedHighlight: v.$light-bg-elevated-highlight,
        bgElevatedPress: v.$light-bg-elevated-press,
        bgUpperBase: v.$light-bg-upper-base,
        bgUpperHighlight: v.$light-bg-upper-highlight,
        bgUpperPress: v.$light-bg-upper-press,
        textBase: v.$light-text-base,
        textBaseInverted: v.$light-text-base,
        textSubdued: v.$light-text-subdued,
        textHighlight: v.$light-text-highlight,
        textBaseHighlight: v.$light-text-base-highlight,
        textPositive: v.$light-text-positive,
        decorativeBase: v.$light-decorative-base,
        decorativeSubdued: v.$light-decorative-subdued,
        decorativeHighlight: v.$light-decorative-highlight,
        decorativeHighlightPress: v.$light-decorative-highlight-press,
        decorativeBranch: v.$color-branch,
        adBackground: v.$color-yellow,
        adText: v.$color-black
    )
);

@mixin themify($themes){
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}
@function themed($key){
    @return map-get($theme-map, $key);
}