@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.swiper-home{
    --container-inline-padding: 2.5rem;
    width: 100%;
    display: block;
    position: relative;
    height: calc(100% - 4rem * 5);
    min-height: calc(100% - 4rem * 5);
    margin-top: -4rem;
    &.lazy{
        .swiper.swiper-container{
            .swiper-wrapper{
                .swiper-slide{
                    .container{
                        .layout{
                            .background{
                                &::before,
                                &::after{
                                    display: none;
                                }
                                @include themify($themes){
                                    background: themed('bgElevatedBase');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .swiper-button{
        display: none;
    }
    .swiper-buttons-group{
        .button{
            .button-text{
                .title{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }
        }
    }
    .swiper-container{
        overflow: visible;
        width: 100%;
        height: 100%;
        .swiper-pagination{
            --progress: 0;
            display: flex;
            padding-top: 2rem;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 255px;
            gap: .5rem;
            bottom: initial;
            left: 50%;
            transform: translateX(-50%);
            .swiper-pagination-bullet{
                position: relative;
                display: flex;
                align-items: center;
                overflow: hidden;
                width: calc(100% / 6);
                border-radius: 100px;
                opacity: 1;
                height: .5rem;
                margin: 0;
                transition: .2s;
                @include themify($themes){
                    background: themed('bgUpperHighlight');
                }
                >span{
                    position: absolute;
                    height: 100%;
                    left: 0;
                    width: calc(1% * var(--progress));
                }
                &-active{
                    opacity: 1;
                    width: 80px;
                    >span{
                        background: v.$color-branch-light;
                    }
                }
            }
        }
    }
    .swiper-wrapper{
        .swiper-slide{
            .container{
                --container-max-width: 84.375rem;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;
                width: 100%;
                height: 100%;
                .layout{
                    --under-hero-whitespace: 18.75rem;
                    display: grid;
                    grid-template-columns: minmax(0,auto);
                    grid-template-rows: minmax(0,auto);
                    min-width: 100%;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    &-grid{
                        grid-column-gap: 0.625rem;
                        align-content: end;
                        align-items: end;
                        aspect-ratio: 1 / 1.15;
                        column-gap: 0.625rem;
                        display: grid;
                        grid-template-columns: repeat(12,1fr);
                        grid-template-rows: 1fr auto;
                        height: 100%;
                        padding-left: var(--container-inline-padding);
                        padding-right: var(--container-inline-padding);
                        width: 100%;
                        @include themify($themes){
                            background: themed('bgBase');
                        }
                        .background{
                            aspect-ratio: 2 / 3;
                            left: 0;
                            width: 100%;
                            pointer-events: none;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            z-index: 0;
                            @include themify($themes){
                                background: themed('bgBase');
                            }
                            &::before,
                            &::after{
                                content: "";
                                position: absolute;
                                width: 100%;
                                top: 0;
                                left: 0;
                                z-index: 1;
                                height: 100%; 
                            }
                            &::before{
                                @include themify($themes){
                                    background: themed('bgBase');
                                    opacity: .6;
                                }
                            }
                            &::after{
                                @include themify($themes){
                                    background: linear-gradient(0deg, themed('bgBase') 0%, rgba(0,0,0,0) 80%, themed('bgBase') 100%);
                                }
                            }
                            img{
                                width: 100%;
                                height: 100%;
                                position: relative;
                            }
                            .wide-variant{
                                display: none;
                            }
                        }
                        .layout-logo{
                            aspect-ratio: 15 / 8;
                            display: grid;
                            grid-column: 4/span 6;
                            grid-row: 1;
                            grid-template-areas: ". . ."
                            " . link-area .";
                            grid-template-columns: 1fr minmax(0,auto) 1fr;
                            grid-template-rows: 1fr minmax(0,auto);
                            max-height: min(20rem, 100%);
                            max-width: 37.5rem;
                            min-height: 0;
                            width: 100%;
                            z-index: 1;
                            .logo-link{
                                grid-area: link-area;
                                height: 100%;
                                width: 100%;
                            }
                            span,
                            img{
                                display: block;
                                height: 100%;
                                object-fit: contain;
                                object-position: bottom center;
                                width: 100%;
                            }
                        }
                        .layout-body{
                            display: grid;
                            grid-column: 1/span 12;
                            grid-row: 2;
                            grid-template-columns: 1fr;
                            height: 100%;
                            justify-items: stretch;
                            min-height: 0;
                            text-align: center;
                            position: relative;
                            .seo-title{
                                height: 0;
                                overflow: hidden;
                                pointer-events: none;
                                position: absolute;
                                width: 0;
                            }
                            .tags-and-rating{
                                justify-content: center;
                                margin: 1.5rem 0 1rem !important;
                            }
                            .description{
                                display: none;
                            }
                            .swiper-buttons-group{
                                display: flex;
                                justify-content: center;
                                margin-top: -0.375rem;
                                gap: .5rem;
                                .button.special-2{
                                    border-color: transparent;
                                    flex: 1;
                                    max-width: inherit;
                                    padding: .6rem 1rem .6rem .6rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.swiper-customize{
    &.loading{
        opacity: .5;
        pointer-events: none;
    }
    &.banner{
        .preview-layout{
            height: 30%;
            flex-flow: column;
            .avatar-layout{
                width: 100%;
            }
        }
        .section{
            .swiper-layout{
                height: 25%;
                .swiper{
                    .swiper-slide{
                        width: 50%;
                        .button{
                            .img{
                                display: flex;
                                border-radius: 2px;
                                aspect-ratio: 4 / 1;
                                @include themify($themes){
                                    background: themed('bgBase');
                                }
                                span{
                                    aspect-ratio: 4 / 1;
                                    display: flex !important;
                                }
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .preview-layout{
        display: flex;
        gap: .5rem;
        padding: .75rem;
        position: sticky;
        top: 0;
        z-index: 2;
        align-items: flex-end;
        @include themify($themes){
            background: themed('bgBase');
            border-bottom: 1px solid themed('decorativeSubdued');
        }
        .avatar-layout{
            width: 100px;
            .avatar{
                width: 100px;
                height: 100px;
            }
            .banner{
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                padding-top: 25%;
                border-radius: 3px;
            }
        }
        .avatar-buttons {
            display: flex;
            justify-content: flex-end;
            height: max-content;
            flex: 1;
            padding-left: 1.5rem;
            gap: .5rem;
            button{
                flex: inherit;
            }
        }
    }
    .section{
        display: flex;
        flex-flow: column;
        gap: .5rem;
        padding: 1rem .5rem;
        @include themify($themes){
            background: themed('bgHighlight');
            border-bottom: 1px solid themed('decorativeSubdued');
        }
        h3{
            font-size: 1.1rem;
            @include themify($themes){
                color: themed('textSubdued');
            }
        }
        .swiper-layout{
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: .25rem;
            height: max-content;
            .swiper-button{
                display: none;
            }
            .swiper{
                width: 100%;
                .swiper-slide{
                    width: 5.75rem;
                    .button{
                        flex-flow: column;
                        width: 100%;
                        .img{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 100%;
                            width: 100%;
                            overflow: hidden;
                            img{
                                width: 100%;
                            }
                        } 
                    }
                }
            }
        }
    }
}
.swiper-layout{
    width: 100%;
    display: flex;
    .swiper-button{
        display: none;
    }
    .swiper{
        flex: 1;
        .swiper-slide{
            width: auto;
        }
    }
}
@media (min-width: 35.5em){
    .swiper-home{
        --container-inline-padding: 2.5rem;
        max-height: initial;
        min-height: 0;
        .swiper-wrapper{
            .swiper-slide{
                .container{
                    .layout{
                        &-grid{
                            aspect-ratio: 1 / 1;
                            .layout-body{
                                grid-column: 3/span 8;
                                justify-content: center;
                                .description{
                                    --line-height: 1.5rem;
                                    font-size: 1rem;
                                }
                                .swiper-buttons-group{
                                    display: inline-flex;
                                    margin-top: -0.3125rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 50em){
    .swiper-home{
        height: auto;
        .swiper-container{
            .swiper-pagination{
                transform: none;
                justify-content: left;
                left: var(--container-inline-padding);
            }
        }
        .swiper-button{
            position: absolute;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            cursor: pointer;
            padding: .5rem;
            button{
                min-width: 0;
                padding: .5rem;
                border-radius: 100%;
                aspect-ratio: 1 / 1;
                @include themify($themes){
                    background: themed('bgBaseTransparent');
                }
            }
            &.button-next{
                right: 0;
            }
            &.button-prev{
                left: 0;
            }
            &:hover{
                button{
                    @include themify($themes){
                        background: themed('bgBase');
                    }
                }
            }
        }
        .swiper-wrapper{
            .swiper-slide{
                .container{
                    .layout{
                        &-grid{
                            aspect-ratio: 20/7;
                            column-gap: 1.25rem;
                            max-height: calc(100vh - 3.75rem - var(--under-hero-whitespace));
                            min-height: 18.75rem;
                            padding-top: 1.25rem;
                            .background{
                                aspect-ratio: 16 / 9;
                                &::before{
                                    opacity: 1 !important;
                                    padding-left: 200%;
                                    @include themify($themes){
                                        background: radial-gradient(circle,  rgba(0,0,0,0) 0%, themed('bgBase') 90%);
                                    }
                                }
                                &::after{
                                    @include themify($themes){
                                        background: linear-gradient(0deg, themed('bgBase') 5%, rgba(0,0,0,0) 40%);
                                    }
                            }
                                .wide-variant{
                                    display: block;
                                }
                                .tall-variant{
                                    display: none;
                                }
                            }
                            .layout-logo{
                                grid-column: 1/span 4;
                                grid-template-areas: "."
                                "link-area";
                                grid-template-columns: minmax(0,auto) 1fr;
                                span,
                                img{
                                    object-position: bottom left;
                                }
                            }
                            .layout-body{
                                grid-column: 1/span 5;
                                justify-items: start;
                                text-align: unset;
                                .tags-and-rating{
                                    margin: 1.5rem 0 .5rem !important;
                                    justify-content: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .swiper-layout{
        .swiper-button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            padding: 0;
            min-width: inherit;
            @include themify($themes){
                color: themed('textBase');
            }
            svg{
                width: 2rem;
                height: 2rem;
            }
        }
    }
    .swiper-customize{
        .section{
            .swiper-layout{
                .swiper-button{
                    width: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.disabled{
                        opacity: .5;
                        pointer-events: none;
                    }
                    > button{
                        width: 100%;
                        height: 100%;
                        min-width: inherit;
                        padding: 0;
                    }
                }
            }
        }
    }
}
@media (min-width: 64em){
    .swiper-home{
        --container-inline-padding: 4rem;
        .swiper-wrapper{
            .swiper-slide{
                .container{
                    .layout{
                        &-grid{
                            column-gap: 1.875rem;
                            min-height: 22.5rem;
                            padding-top: 2.5rem;
                            .layout-logo{
                                grid-column: 1/span 3;
                            }
                            .layout-body{
                                grid-column: 1/span 4;                                
                                .description{
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    display: -webkit-box;
                                    margin-bottom: 2rem;
                                    overflow: hidden;
                                    white-space: unset;
                                    @include themify($themes){
                                        color: themed('fontSubdued');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}