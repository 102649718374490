@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.header.auth{
    @include themify($themes){
        background: rgba($color: themed('bgBase'), $alpha: 0);
    }
    .section{
        .logo{
            a{
                @include themify($themes){
                    color: themed('textBase');
                }
                transition: .1s ease all;
                svg{
                    fill: currentColor;
                    path{
                        fill: currentColor !important;
                    }
                }
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
    }
}
.auth-with-background {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    margin-top: -4rem;
    position: relative;
    &.loading{
        .content{
            .content-body{
                pointer-events: none;
                filter: blur(2px);
            }
        }
    }
    .background{
        position: absolute;
        inset: 0;
        z-index: 0;
        .lazy.image{
            @include themify($themes){
                background: themed('bgBase');
            }
        }
        &::before,
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%; 
        }
        &::before{
            @include themify($themes){
                background: themed('bgBase');
                opacity: .5;
            }
        }
        &::after{
            @include themify($themes){
                background: linear-gradient(0deg, rgba($color: themed('bgBase'), $alpha: 1) 10%, rgba($color: themed('bgBase'), $alpha: .5) 70%, rgba($color: themed('bgBase'), $alpha: .8) 100%);
            }
        }
    }
    .content{
        padding-block: 5rem;
        // padding: 4rem 1rem 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        min-height: 100vh;
        position: relative;
        justify-content: center;
        align-items: center;
        .loader{
            margin-top: -10%;
            position: absolute;
            z-index: 2;
            padding: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
             @include themify($themes){
                background: rgba($color: themed('bgBase'), $alpha: .75);
            }
        }
        .content-body{
            display: flex;
            flex-flow: column;
            gap: 1rem;
            justify-content: center;
            max-width: 400px;
            width: 100%;
            border-radius: calc(1rem + .25rem);
            padding: 1rem;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            transition: .2s ease all;
            @include themify($themes){
                border: 1px solid rgba($color: themed('textBase'), $alpha: .1);
                background: rgba($color: themed('bgBase'), $alpha: .3);
            }
            
            h1{
                font-size: 1.425rem;
                font-weight: 500;
                text-align: center;
                margin-bottom: .5rem;
                @include themify($themes){
                    color: themed('textBase');
                }
            }
            .avatar{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .75rem;
                margin-bottom: 1rem;
                &.stepOne{
                    .button{
                        margin-left: 0;
                    }
                    .user-data{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .button{
                    font-size: 2rem;
                    min-width: 0;
                    margin-left: calc(50% - 5em / 2);
                    > svg{
                        font-size: 1.5em;
                    }
                }
                .user-data{
                    display: flex;
                    flex-flow: column;
                    gap: .25rem;
                    flex: 1;
                    opacity: 0;
                    visibility: hidden;
                    transition: .2s ease all;
                    > span{
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    .dn{
                        font-size: 1.25rem;
                    }
                    .un{
                        @include themify($themes) {
                            color: themed('textSubdued');
                        }
                    }
                }
            }
            
            .input,
            .MuiInputBase-root,
            .buttons .button{
                border-radius: .5rem;
                font-size: .9rem;
            }
            .buttons{
                display: flex;
                flex-flow: row;
                gap: 1rem;
            }
            .buttons .button{
                border-radius: 100px;
                flex: 1;
            }
        }
    }
}

.complete-auth{
    .content{
        .content-body{
        }
    }
}