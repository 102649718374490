@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.premium-header{
    position: relative;
    width: 100%;
    min-height: 50vh;
    &_body{
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        &-data{
            padding: 1rem;
            width: 100%;
            max-width: 1000px;
            h1{
                font-size: 6.5vmin;
                text-align: center;
            }
            .link{
                margin: 0 auto;
                width: max-content !important;
                margin-top: 1rem;
            }
        }
    }
    &_background{
        position: absolute;
        top: -4rem;
        left: 0;
        width: 100%;
        z-index: 0;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before,
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%; 
        }
        &::before{
            @include themify($themes){
                background: themed('bgBase');
                opacity: .6;
            }
        }
        &::after{
            @include themify($themes){
                background: linear-gradient(0deg, themed('bgBase') 10%, rgba(0,0,0,0) 80%);
            }
        }
        picture,
        span{
            display: flex !important;
            justify-content: center;
            align-items: center;
            flex: 1;
            min-height: 500px;
        }
        img{
            min-width: 100%;
            min-height: 500px;
        }
    }
}
.premium-perks{
    position: relative;
    z-index: 1;
    max-width: 1000px;
    margin: 0 auto;
    .perk-special{
        padding: 1rem;
        display: flex;
        flex-flow: row wrap;
        margin: 5rem auto 0;
        gap: 1rem;
        width: 100%;
        &-thumbnail{
            flex: 1;
            min-width: 300px;
            img,
            span{
                aspect-ratio: 16 / 9;
                width: 100%;
            }
        }
        &-data{
            min-width: 55%;
            flex: 1;
            h3{
                font-size: 4.8vmin;
                font-family: v.$font-branch;
            }
        }
    }
    .perks-layout{
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 1rem;
        gap: 1rem;
        .perks{
            display: flex;
            flex-flow: column;
            gap: 2rem;
            .perks-title{
                text-transform: uppercase;
                font-weight: bold;
                font-size: 2.5rem;
                text-align: center;
                color: v.$color-branch-light;
                font-family: v.$font-branch;
            }
            .perks-body{
                display: flex;
                flex-flow: row wrap;
                row-gap: 1rem;
                .perk{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    &.l{
                        flex-flow: row;
                    }
                    &.r{
                        flex-flow: row-reverse;
                        .perk-text{
                            text-align: right;
                        }
                    }
                    .perk-image{
                        width: 100%;
                        max-width: 100px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 100%;
                        }
                    }
                    .perk-text{
                        padding-left: .5rem;
                        max-width: 400px;
                        h3{
                            font-size: 1.4rem;
                        }
                        p{
                            @include themify($themes){
                                color: themed('textSubdued');
                            }
                        }
                    }
                }
            }
        }
    }
}
.premium-plans{
    padding: 10rem 1rem 2rem;
    margin-top: 5rem;
    position: relative;
    @include themify($themes){
        background: themed('bgElevatedBase');
    }
    .plans_background{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%; 
        }
        &::before{
            @include themify($themes){
                background: themed('bgBase');
                opacity: .8;
            }
        }
        picture,
        span,
        img{
            display: flex;
            flex: 1;
        }
        img{
            min-width: 100%;
        }
    }
    &_layout{
        display: flex;
        align-items: center;
        flex-flow: column;
        position: relative;
        &-body{
            margin-bottom: 5rem;
            display: flex;
            justify-content: space-evenly;
            flex-flow: row wrap;
            flex: 1;
            .premium-plans_card{
                display: flex;
                flex-flow: column;
                padding: 2rem .5rem 1rem;
                max-width: 400px;
                width: 100%;
                flex: 1;
                position: relative;
                @include themify($themes){
                    background: themed('bgBase');
                }
                &-background{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 0;
                    .icon{
                        position: absolute;
                        top: -1rem;
                        right: -1rem;
                        z-index: 1;
                        svg{
                            font-size: 3.5rem;
                            width: 1em;
                            height: 1em;
                        }
                    }
                    .bg {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        opacity: .15;
                    }
                }
                &-header{
                    display: flex;
                    flex-flow: column;
                    text-align: center;
                    font-family: v.$font-branch;
                    position: relative;
                    .plan-name{
                        text-transform: uppercase;
                        font-size: 1.5rem;
                        font-family: v.$font-branch;
                    }
                    .plan-price{
                        font-size: 2rem;
                        font-weight: bold;
                        .price{
                            margin-right: .5rem;
                        }
                        .period{
                            font-size: 1.25rem;
                        }
                    }
                    .plan-buy{
                        padding: 1rem;
                        .button{
                            width: 100%;
                        }
                    }
                }
                .divider{
                    margin: .5rem 0;
                }
                &-footer{
                    position: relative;
                    padding: 1rem;
                    @include themify($themes){
                        color: themed('textSubdued');
                    }
                }
            }
        }
        .disclaimer{
            font-size: .7rem;
            max-width: 700px;
            text-align: center;
        }
    }
}