$performancer: (
    high: (
        transition: .2s ease all,
        transitionFast: .1s ease all,
        transitionSlow: 2s ease all,
    ),
    low: (
        transition: 0,
        transitionFast: 0,
        transitionSlow: 0,
    )
);

@mixin performify($performancer){
    @each $performance, $map in $performancer {
        .performance-#{$performance} & {
            $performance-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($performancer, $performance), '#{$key}');
                $performance-map: map-merge(
                    $performance-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $performance-map: null !global;
        }
    }
}
@function performed($key){
    @return map-get($performance-map, $key);
}