@import '../styles/theme-variables.scss';
.refreshing{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .circular-progress{
        width: 2.5rem !important;
        height: 2.5rem !important;
        @include themify($themes){
            color: themed('textBase');
        }
    }
}
.home-content{
    --content-padding: 1.25rem;
    --card-padding: .25rem;
    position: relative;
    margin-top: 4.5rem;
    z-index: 1;
    .section-layout{
        --tall-cards-carousel-cols: 2;
        --wide-cards-carousel-cols: 1.2;
        --line-height: 1.75rem;
        margin: 0 auto var(--content-padding);
        flex: 1;
        &.wide{
            .section-content{
                .swiper-slide{
                    width: calc(100% / var(--wide-cards-carousel-cols) - (var(--content-padding)));
                }
            }
        }
        &.lazy{
            .section-title{
                .title{
                    max-width: 400px;
                    height: var(--line-height);
                    padding-bottom: 0;
                    margin-bottom: .75rem;
                    @include themify($themes){
                        background: themed('bgElevatedHighlight');
                    }
                }
            }
            .section-content{
                .swiper{
                    .swiper-wrapper{
                        display: flex;
                        flex-flow: row;
                    }
                }
            }
        }
        .section-title{
            --container-max-width: 84.375rem;
            margin: 0 auto;
            max-width: 100%;
            width: 100%;
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);
            .title{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-bottom: .75rem;
                position: relative;
                h2{
                    font-weight: 400;
                    font-size: 1.375rem;
                }
            }
        }
        .section-content{
            position: relative;
            display: flex;
            align-items: center;
            .section-wrapper{
                padding-left: var(--content-padding);
                padding-right: var(--content-padding);
                width: 100%;
                &__body{
                    padding: .25rem;
                    width: 100%;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    img{
                        max-width: 200px;
                    }
                }
                .loading-section{
                    display: flex;
                    justify-content: center;
                    .circular-progress{
                        width: 3.5rem !important;
                        height: 3.5rem !important;
                        @include themify($themes){
                            color: themed('textBase');
                        }
                    }
                }
            }
            .swiper{
                width: 100%;
                margin: calc(var(--card-padding) * -1);
            }
            .swiper-button{
                display: none;
            }
            .swiper-slide{
                width: calc(100% / var(--tall-cards-carousel-cols) - (var(--content-padding)));
                &:nth-child(1){
                    margin-left: var(--content-padding);
                }
                &:nth-last-child(1){
                    margin-right: var(--content-padding);
                }
            }
        }
    }
}

@media (min-width: 30em) {
    .home-content{
        --card-padding: .25rem;
        .section-layout{
            --tall-cards-carousel-cols: 2.9;
            --wide-cards-carousel-cols: 2;
        }
    }
}

@media (min-width: 35.5em) {
    .home-content{
        --card-padding: .5rem;
        .section-layout{
            --tall-cards-carousel-cols: 3;
            --wide-cards-carousel-cols: 2.25;
            --line-height: 2rem;
            .section-title{
                .title{
                    padding-bottom: 1rem;
                    h2{
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 50em) {
    .home-content{
        --content-padding: 2.5rem;
        .section-layout{
            --tall-cards-carousel-cols: 3.5;
            --wide-cards-carousel-cols: 2.75;
            --line-height: 2.25rem;
            .section-title{
                .title{
                    h2{
                        font-size: 1.5rem;
                    }
                }
            }
            .section-content{
                .swiper-button{
                    position: absolute;
                    z-index: 2;
                    display: block;
                    min-width: inherit;
                    height: 100%;
                    opacity: 1;
                    border-radius: 0;
                    padding: 0;
                    transition: .2s ease all;
                    background: transparent;
                    @include themify($themes){
                        color: themed('textSubdued');
                    }
                    &:hover{
                        @include themify($themes){
                            color: themed('textBase');
                        }
                        &::before,
                        &::after{
                            opacity: .8;
                        }
                        svg{
                            @include themify($themes){
                               background: themed('bgElevatedBase');
                            }
                        }
                    }
                    &.disabled{
                        opacity: 0;
                        visibility: hidden;
                    }
                    svg{
                        font-size: 3rem;
                        z-index: 1;
                        padding: .5rem;
                        border-radius: 100%;
                        position: relative;
                        @include themify($themes){
                            background: themed('bgElevatedHighlight');
                        }
                    }
                    &::before,
                    &::after{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: .2;
                        transition: .2s ease all;
                    }
                    &.button-prev{
                        left: 0;
                        padding-left: .5rem;
                    }
                    &.button-next{
                        right: 0;
                        padding-right: .5rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 64em) {
    .home-content{
        --content-padding: 4rem;
        .section-layout{
            --tall-cards-carousel-cols: 4;
            --wide-cards-carousel-cols: 3;
        }
    }
}

@media (min-width: 70em) {
    .home-content{
        --card-padding: .75rem;
    }
}

@media (min-width: 80em) {
    .home-content{
        .section-layout{
            --tall-cards-carousel-cols: 5;
            --wide-cards-carousel-cols: 3.5;
        }
    }
}
@media (min-width: 90em) {
    .home-content{
        margin-top: 6rem;
        .section-layout{
            --tall-cards-carousel-cols: 6;
            --wide-cards-carousel-cols: 4;
        }
    }
}
@media (min-width: 100em) {
    .home-content{
        .section-layout{
            --wide-cards-carousel-cols: 5;
        }
    }
}