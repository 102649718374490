@import '../../styles/theme-variables.scss';
.search{
    display: flex;
    flex-flow: column;
    min-height: 70vh;
    &__input-layout{
        width: 100%;
        padding: .5rem .5rem .5rem;
        margin: 0 auto;
        position: sticky;
        top: 4rem;
        z-index: 1;
        @include themify($themes){
            background: themed('bgBase');
        }
        .input-body{
            margin: 0 auto;
            width: 100%;
            max-width: 900px;
        }
        .input{
            width: 100%;
            font-size: 1.5rem;
            @include themify($themes){
                border-bottom: 2px solid themed('decorativeBranch');
            }
        }
    }
    &__layout{
        display: flex;
        flex-flow: column;
        margin-top: 2rem;
        padding-bottom: 1rem;
        gap: .5rem;
        &-title{
            width: 100%;
            max-width: 1200px;
            padding: 0 .75rem;
            margin: 0 auto;
            font-weight: 500;
            font-size: 1.25rem;
        }
    }
    &__results{
        &-layout{
            display: flex;
            justify-content: center;
            width: 100%;
            &.relevant{
                .search__results{
                    &-body{
                        &.has-1{
                            .result-card{
                                &__body{
                                    h4{
                                        font-size: 1.25rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-body{
            --result-columns: 2;
            display: flex;
            flex-flow: row wrap;
            max-width: 1200px;
            width: 100%;
            margin: 0 -.75rem;
            &.has-1{
                .result-card{
                    max-width: 100%;
                }
            }
            &.has-2{
                .result-card{
                    max-width: 100%;
                }
            }
            .result-card{
                width: calc(100% / var(--result-columns));
                max-width: inherit;
                padding: .75rem;
                &:hover{
                    @include themify($themes){
                        background: themed('bgElevatedBase');
                    }
                }
                &.result-prior-0{
                    width: 100%;
                }
                &.result-prior-1,
                &.result-prior-2{
                    width: 50%;
                    .result-card{
                        &__thumbnail{
                            aspect-ratio: 2 / 3;
                        }
                    }
                }
                &__thumbnail{
                    aspect-ratio: 16 / 9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include themify($themes){
                        background: themed('bgElevatedBase');
                    }
                    span{
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                    }
                    img{
                        width: 100%;
                    }
                }
                &__body{
                    h4{
                        margin: .5rem 0;
                        font-weight: 600;
                        font-family: 'Montserrat', sans-serif;
                        @include themify($themes){
                            color: themed('textBase');
                        }
                    }
                }
            }
        }
        &-error{
            max-width: 500px;
            width: 100%;
            &-msg{
                display: flex;
                flex-flow: column;
                gap: .25rem;
                margin-bottom: 1.5rem;
                text-align: center;
                span{
                    font-size: 1.25rem;
                }
            }
            .form{
                padding: 0 1rem;
                .button{
                    width: 100%;
                    margin-top: .5rem;
                }
            }
        }
    }
}

@media (min-width: 41.5em) {
    .search{
        &__results-body{
            .result-card{
                &.result-prior-0{
                    width: calc(100% - (22.14% * 2));
                }
                &.result-prior-1,
                &.result-prior-2{
                    width: 22.14%;
                }
            }
        }
    }
}

@media (min-width: 50em) {
    .search{
        &__results-body{
            --result-columns: 3;
            &.has-1{
                .result-card{
                    max-width: 50%;
                }
            }
        }
    }
}

@media (min-width: 60em) {
    .search{
        &__results-body{
            --result-columns: 4;
        }
    }
}
