@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';

.nav-tabs{
    position: sticky;
    top: calc(100% - 4.5rem);
    bottom: 0;
    z-index: 96;
    height: 4.5rem;
    @include themify($themes){
        background: themed('bgBase');
        border-top: 1px solid themed('decorativeSubdued');
    }
    &-layout{
        .nav-tabs-body{
            display: flex;
            flex-flow: row;
            justify-content: space-around;
            .nav-button{
                border-radius: 0;
                flex: 1;
                @include themify($themes){
                    color: themed('textSubdued');
                }
                display: flex;
                flex-flow: column;
                gap: .25rem;
                &.active{
                    @include themify($themes){
                        color: themed('textBase');
                    }
                    .icon{
                        min-width: 90%;
                        @include themify($themes){
                            background: themed('bgElevatedBase');
                        }
                        .outlined{
                            display: none;
                        }
                        .filled{
                            display: flex;
                        }
                    }
                }
                .icon{
                    transition: .2s ease all;
                    min-width: 0%;
                    padding: .25rem .5rem;
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .filled{
                        display: none;
                    }
                    .svg{
                        width: 1.5rem;
                        fill: currentColor;
                        height: 1.5rem;
                    }
                }
                .name{
                    font-size: .875rem;
                }
            }
        }
    }
}