@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    &.show{
        > .backdrop {
            visibility: visible;
            opacity: .5;
        }
        > .modal_layout{
            scale: 1;
            visibility: visible;
            opacity: 1;
        }
    }
    &.loading {
        > .modal-layout {
            &::before{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    > .backdrop{
        opacity: 0;
        visibility: hidden;
    }
    &.padding-false{
        .modal_layout .content{
            padding: 0;
        }
    }
    &.variant-fs{
        .modal_layout{
            height: 100%;
            max-width: initial;
            border: none;
            transition: .4s ease all;
            opacity: 0;
            visibility: hidden;
            border: none;
            scale: 1;
            margin: 0;
            border-radius: 0;
            .content{
                height: 100%;
            }
        }
        &.show{
            .modal_layout{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.variant-fh{
        .modal_layout{
            height: 100%;
            max-height: 95%;
            overflow: hidden;
            .content{
                height: 100%;
            }
        }
    }
    .modal_layout{
        position: relative;
        display: flex;
        flex-flow: column;
        border-radius: 10px;
        scale: .9;
        transition: .2s ease all;
        visibility: hidden;
        opacity: 0;
        max-width: 600px;
        margin: .5rem;
        width: 100%;
        overflow: hidden;
        @include themify($themes){
            border: 1px solid themed('decorativeSubdued');
            background: themed('bgBase');
        } 
        .title-layout{
            display: flex;
            padding: .5rem;
            width: 100%;
            position: sticky;
            top: 0;
            .title{
                flex: 1;
                font-size: 1.15rem;
                font-weight: 400;
                line-height: 1;
                padding: .5rem;
                @include themify($themes){
                    color: themed('textBase');
                }
            }
        }
        .back-button{
            position: sticky;
            top: -.75rem;
            z-index: 2;    
            margin: -.75rem -1rem 1.75rem;
            @include themify($themes){
                background: themed('bgBase');
            } 
        }
        .content{
            width: 100%;
            overflow: auto;
            height: max-content;
            max-height: 100%;
            padding: .5rem 1rem;
            .image-editor{
                position: relative;
                display: flex;
                .cutter{
                    position: absolute;
                    height: 100%;
                    border: 5px solid v.$dark-text-base;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    aspect-ratio: 1 / 1;
                    border-radius: 100%;
                    pointer-events: none;
                }
            }
            
            .avatar-controls{
                margin-top: 1.5rem;
                display: flex;
                flex-flow: column;
                gap: 1rem;
                margin-bottom: 2rem;
                .slider{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    span{
                        @include themify($themes){
                            color: themed('textBase');
                        } 
                    }
                }
                .buttons-group{
                    button{
                        flex: 1;
                        padding: .5rem;
                        @include themify($themes){
                            color: themed('textBase');
                            border-color: themed('decorativeSubdued');
                        } 
                    }
                }
            }

            .share{
                display: flex;
                flex-flow: row;
                gap: .5rem;
                position: relative;
                .swiper-button{
                    position: absolute;
                    display: block;
                    min-width: inherit;
                    height: 100%;
                    opacity: 1;
                    border-radius: 0;
                    font-size: 1rem;
                    padding: 0;
                    z-index: 4;
                    transition: .2s ease all;
                    background: transparent;
                    cursor: pointer;
                    &.swiper-button-disabled{
                        opacity: .4;
                        pointer-events: none;
                    }
                    &.button-next{
                        right: -.5rem;
                    }
                    &.button-prev{
                        left: -.5rem;
                    }
                    svg{
                        font-size: 3em;
                        padding: .5rem;
                        border-radius: 100%;
                        position: relative;
                        @include themify($themes){
                            background: themed('bgElevatedBase');
                        } 
                    }
                }
                .swiper-container{
                    width: calc(100% + 1rem * 2);
                    padding-bottom: 1rem;
                    margin: 0 -1rem;
                    .swiper{
                        padding: 0 3.5rem;
                    }
                    .swiper-btn{
                        width: max-content;
                        a, button{
                            display: flex;
                            flex-flow: column;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            gap: .25rem;
                            @include themify($themes){
                                color: themed('textSubdued');
                            } 
                            &:hover{
                                .icon{
                                    @include themify($themes){
                                        background: themed('bgHighlight');
                                    } 
                                }
                            }
                            .icon{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 100%;
                                padding: 1rem;
                                svg{
                                    font-size: 2rem;
                                }
                            }
                            .name{
                                font-size: 1rem;
                                font-family: v.$font-primary;
                                text-align: center;
                                width: max-content;
                            }
                        }
                    }
                }
            }
            
            .QR{
                display: flex;
                justify-content: center;
                padding: 1rem;
                > div{
                    padding: .5rem;
                    background: #FFF;
                }
                path{
                    @include themify($themes){
                        fill: #000;
                    }  
                    &:nth-child(1){
                        fill: #FFF;
                    }
                }
            }
        }
        .modal-buttons{
            display: flex;
            gap: 1rem;
            margin: 1rem 0 .5rem;
        }
    }
    > .modal-layout{
        position: relative;
        max-width: 600px;
        width: 100%;
        scale: .85;
        visibility: hidden;
        opacity: 0;
        transition: .2s ease all;
        margin: .5rem;
        border-radius: 5px;
        @include themify($themes){
            border: 1px solid themed('decorativeSubdued');
            background: themed('bgBase');
        } 
        &.buy-premium{
            height: 100%;
            max-height: 70%;
            display: flex;
            flex-flow: column;
            overflow: hidden;
            .modal-body{
                position: relative;
                flex: 1;
                overflow-y: scroll;
                .loading{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    align-items: center;
                    opacity: 0;
                    visibility: hidden;
                    transition: .2s ease all;
                    z-index: 2;
                    @include themify($themes){
                        background: themed('bgBaseTransparent');
                    } 
                    &.show{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &_content{
                    height: 100%;
                    position: relative;
                }
                .plans{
                    display: flex;
                    flex-flow: column;
                    padding: 1rem;
                    gap: 1rem;
                    .premium-plans_card{
                        display: flex;
                        flex-flow: column;
                        padding: 2rem .5rem 1rem;
                        width: 100%;
                        flex: 1;
                        position: relative;
                        line-height: normal;
                        font-family: v.$font-branch;
                        text-transform: none;
                        @include themify($themes){
                            color: themed('textBase');
                            background: themed('bgBase');
                        }
                        &:hover{
                            .premium-plans_card-background{
                                .icon{
                                    .first{
                                        @include themify($themes){
                                            color: themed('textBase');
                                        }
                                    }
                                }
                                .bg{
                                    opacity: .4;
                                }
                            }
                            .premium-plans_card-footer{
                                @include themify($themes){
                                    color: themed('textBase');
                                }
                            }
                        }
                        &-background{
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 0;
                            .icon{
                                position: absolute;
                                top: -.5rem;
                                right: -.5rem;
                                z-index: 1;
                                @include themify($themes){
                                    color: themed('textSubdued');
                                }
                                svg{
                                    transition: .2s ease all;
                                    font-size: 2rem;
                                    width: 1em;
                                    height: 1em;
                                }
                            }
                            .bg {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-position: center;
                                background-size: cover;
                                background-repeat: no-repeat;
                                opacity: .15;
                                transition: .2s ease all;
                            }
                        }
                        &-header{
                            display: flex;
                            flex-flow: column;
                            text-align: center;
                            font-family: v.$font-branch;
                            position: relative;
                            .plan-name{
                                text-transform: uppercase;
                                font-size: 1.5rem;
                                font-family: v.$font-branch;
                            }
                            .plan-price{
                                font-size: 2rem;
                                font-weight: bold;
                                .price{
                                    margin-right: .5rem;
                                }
                                .period{
                                    font-size: 1.25rem;
                                }
                            }
                        }
                        &-footer{
                            position: relative;
                            padding: 1rem;
                            transition: .2s ease all;
                            @include themify($themes){
                                color: themed('textSubdued');
                            }
                        }
                    }
                }
                .payment-method{
                    color-scheme: normal;
                    height: 100%;
                    .paypal{
                        padding: 1rem;
                        background: #FFF;
                        min-height: calc(100% - 3.7rem);
                        position: relative;
                        z-index: 0;
                    }
                }
                .buttons-layout{
                    position: sticky;
                    bottom: -1px;
                    border-radius: 0;
                    z-index: 2;
                }
                .message{
                    padding: 1rem;
                    display: flex;
                    flex-flow: column;
                    gap: .75rem;
                    min-height: calc(100% - 3.6rem);
                    .expire-date{
                        padding: .5rem;
                        @include themify($themes){
                            color: themed('textPositive');
                            background: themed('bgHighlight');
                            border: 1px solid themed('decorativeSubdued');
                        } 
                    }
                    .image{
                        margin-top: 1rem;
                        aspect-ratio: 16 / 9;
                        display: flex;
                        overflow: hidden;
                        align-items: center;
                        border-radius: 5px;
                        span,img{
                            width: 100%;
                        }
                    }
                }
                .payment-methods{
                    padding: 1rem;
                    .plan-price{
                        font-size: 2rem;
                        font-weight: bold;
                        margin: 0 auto;
                        width: max-content;
                        .price{
                            margin-right: .5rem;
                        }
                        .period{
                            font-size: 1.25rem;
                        }
                    }
                    .divider{
                        margin: 2rem 0 1rem;
                    }
                    .methods{
                        margin-top: 1rem;
                        padding-top: 1rem;
                        display: flex;
                        flex-flow: column;
                        gap: .5rem;
                        .button{
                            padding: .25rem;
                            border-radius: 2px;
                            .button-icon{
                                aspect-ratio: 16 / 9;
                                @include themify($themes){
                                    background: themed('bgUpperHighlight');
                                } 
                                img{
                                    height: 2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.small{
            max-width: 500px;
        }
        &.default-mw{
            max-width: 600px !important;
        }
        &.embed{
            max-width: 900px;
            width: 97%;
            color-scheme: light;
            .modal-body{
                width: 100%;
                iframe{
                    aspect-ratio: 16 / 9;
                    width: 100%;
                }
            }
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: .1s;
            position: absolute;
            background: #00000070;
            z-index: 3;
        }
        .modal-title{
            display: flex;
            align-items: center;
            padding-left: .5rem;
            justify-content: space-between;
            @include themify($themes){
                border-bottom: 1px solid themed('decorativeSubdued');
                color: themed('textBase');
            } 
            .IconButton{
                width: auto !important;
            }
            h3{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
        }
        .modal-body{
            display: flex;
            flex-flow: column;
            max-height: 70%;
            .gif{
                span{
                    width: 100%;
                    display: flex;
                }
            }
            .ad-body{
                padding: .75rem .75rem .5rem;
                display: flex;
                flex-flow: column;
                gap: .75rem;
                .banner{
                    aspect-ratio: 16 / 9;
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    @include themify($themes){
                        background: themed('bgElevatedBase');
                    } 
                    span{
                        width: 100%;
                        height: 100%;
                    }
                    img{
                        width: 100%;
                    }
                }
            }
            .customize-selector{
                display: flex;
                gap: 1rem;
                padding: 1rem;
                width: 100%;
                justify-content: center;
                .button{
                    flex: .5;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center !important;
                    .button-icon{
                        svg {
                            width: 2.5rem;
                            height: 2.5rem;
                        }
                    }
                    .button-text{
                        .title{
                            justify-content: center;
                            font-size: 1.2rem;
                        }
                        .description{
                            text-align: center;
                        }
                    }
                }
            }
            .buttons-layout{
                display: flex;
                gap: .5rem;
                width: 100%;
                justify-content: flex-end;
                padding: .5rem;
                @include themify($themes){
                    background: themed('bgHighlight');
                } 
                .button{
                    width: auto;
                }
            }
        }
        &.avatar{
            max-width: 500px;
            border-radius: 5px;
        }
        &.banner{
            max-width: 900px;
        }
        &.avatar,
        &.banner{
            .modal-body{
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                .avatar-editor{
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    &.avatar{
                        position: relative;
                        &::before{
                            --border-width: 4px;
                            content: "";
                            pointer-events: none;
                            height: calc(100% - (var(--border-width) * 2));
                            aspect-ratio: 1 / 1;
                            border-radius: 100%;
                            border: var(--border-width) solid v.$dark-decorative-base;
                            z-index: 1;
                            position: absolute;
                        }
                    }
                    &.banner{
                        &::before{
                            --border-width: 4px;
                            content: "";
                            pointer-events: none;
                            top: 50%;
                            transform: translateY(-50%);
                            aspect-ratio: 4 / 1;
                            width: calc(100% - (var(--border-width) * 2));
                            border: var(--border-width) solid v.$dark-decorative-base;
                            z-index: 1;
                            position: absolute;
                        }
                    }
                }
                .avatar-controls{
                    display: flex;
                    width: 100%;
                    padding: .5rem;
                }
                .slider{
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    width: 100%;
                    flex: 1;
                    padding: .5rem;
                    .MuiSlider-root{
                        flex: 1;
                        color: v.$color-branch;
                    }
                }
            }
        }
    }
}
@media (max-width: 799px){
    .modal{
        > .modal-layout{
            scale: 1;
            width: calc(100% - 1rem);
            margin-bottom: .5rem;
            bottom: -70%;
            position: absolute;
        }
        .modal_layout{
            scale: 1;
            position: absolute;
            bottom: -100%;
        }
        &.show{
            >.modal-layout{
                bottom: 0;
            }
            .modal_layout{
                bottom: 0;
                max-height: 90%;
            }
        }
        
        
        &.variant-fh,
        &.variant-fs{
            .modal_layout{
                max-width: initial;
                bottom: initial;
                max-height: 100%;
                border-radius: 0;
            }
        }
    }
}