@use 'variables' as v;
@import './theme-variables.scss';
$font-branch: 'Montserrat', sans-serif;
$font-primary: "Roboto", sans-serif;

*{
    box-sizing: border-box;
    background: transparent;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    scroll-behavior: smooth;
    font-family: $font-primary;
}
html{
    --color-scheme: dark;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    color-scheme: var(--color-scheme);
    font-size: 16px;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
body{
    --innerHeight: 0;
    font-family: $font-primary;
    overflow: visible;
    @include themify($themes){
        color: themed('textBase');
    } 
    &.theme-dark{
        background: v.$dark-bg-base;
        #root{
            background: v.$dark-bg-base;
        }
    }
    &.theme-light{
        background: v.$light-bg-base;
        #root{
            background: v.$light-bg-base;
        }
    }
    #root{
        width: 100%;
        // height: var(--innerHeight);
        // overflow-y: scroll;
        // overflow-x: hidden;
        @include themify($themes){
            color: themed('textBase');
        } 
    }
}
*::selection{
    background: v.$color-branch;
    color: v.$dark-text-base;
}
a{
    text-decoration: none;
}
.link{
    display: flex;
    gap: .5rem;
    align-items: center;
    @include themify($themes){
        color: themed('textHighlight');
    } 
    &.uppercase{
        text-transform: uppercase;
    }
    &.bold{
        font-weight: bold;
    }
    &:hover{
        @include themify($themes){
            color: themed('textBase');
        } 
    }
    svg{
        width: 1em;
        height: 1em;
    }
}
#disqus_thread {
    padding: .5rem;
    margin: 0 auto;
    width: 100%;
    color-scheme: normal;
    a{
        @include themify($themes){
            color: themed('textHighlight');
        } 
    }
}
.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: .5;
    transition: .2s ease all;
    visibility: hidden;
    background: v.$dark-bg-press;
    &.true{
        visibility: visible;
    }
}
.progress{
    display: flex;
    justify-content: center;
    padding: .75rem .5rem;
    .progress-circular{
        color: v.$color-branch;
        &.normal{
            @include themify($themes){
                color: themed('textBase');
            }
        }
    }
}
div.MuiTooltip-tooltip {
    padding: .5rem !important;
    border-radius: 100px !important;
    font-family: v.$font-branch !important;
    font-weight: bold !important;
    font-size: .8rem !important;
    @include themify($themes){
        background: themed('bgElevatedBase') !important;
        border: 1px solid themed('decorativeSubdued');
    }
}
.svg{
    width: 1em;
    fill: currentColor;
    color: inherit;
}
.progress{
    &.circular{
        padding: 0;
    }
    &.linear{
        padding: 0;
        background: transparent;
        border-radius: 100px;
        span{
            @include themify($themes){
                background: themed('textBase');
            }
        }
    }
    @include themify($themes){
        color: themed('textBase');
    }
}
.border{
    &-bottom{
        @include themify($themes){
            border-bottom: 1px solid themed('decorativeSubdued');
        }
    }
    &-top{
        @include themify($themes){
            border-top: 1px solid themed('decorativeSubdued');
        }
    }
}

@mixin blur($br) {
    filter: blur(#{$br}px) !important;
}
.blur{
    @for $i from 1 through 100 {
        &-#{$i} {
            @include blur($i)
        }
    }
}


.lazy{
    &.image{
        position: relative;
        overflow: hidden;
        transition: .3s;
        figure{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
        }
        @include themify($themes){
            background: themed('bgElevatedHighlight');
        }
        .image-body{
            opacity: 0;
            transition: .3s;
            img{
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        &.loaded{
            .image-body{
                opacity: 1;
            }
        }
    }
}
.w100{
    width: 100%;
}
.divider{
    width: 100%;
    height: 1px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themify($themes){
        background: themed('decorativeSubdued');
    }
    &.with-o{
        pointer-events: none;
        span{
            position: absolute;
            padding: .5rem;
            line-height: 1;
            text-transform: uppercase;
            @include themify($themes){
                color: themed('textSubdued');
                background: themed('bgBase');
            }
        }
    }
}
.avatar{
    background: transparent;
    color: currentColor;
    border-radius: 100px;
    &.image{
        @include themify($themes){
            color: themed('textSubdued');
            background: themed('bgElevatedHighlight');
        }
    }
    svg{
        color: currentColor;
        fill: currentColor;
    }
}
.account-banner{
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    padding: 0 !important;
    @include themify($themes){
        color: themed('textBase');
    }
    .account-banner-sizer{
        height: 0;
        width: 100%;
        padding-top: 25%;
        position: relative;
        .account-banner-badges{
            position: absolute;
            top: .5rem;
            left: .5rem;
            z-index: 1;
            display: flex;
            gap: .5rem;
            .badge{
                width: 3rem;
                height: 3rem;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include themify($themes){
                    color: themed('textBase');
                    background: themed('bgElevatedBase');
                }
                svg{
                    width: 1.5rem !important;
                    color: currentColor;
                }
            }
        }
        .account-banner-image{
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include themify($themes){
                background-color: themed('bgElevatedBase');
            }
        }
        .edit-icon{
            position: absolute;
            top: .5rem;
            right: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            padding: .5rem;
            @include themify($themes){
                background: themed('bgUpperBase');
            }
            svg{
                font-size: 2rem;
            }
        }
    }
}

.background{
    &-high{
        @include themify($themes){
            background: themed('bgHighlight');
        }
    }
}

@mixin padding($direction, $size) {
    @if $direction == 'all' {
        padding: #{$size / 10}rem;
    } @else {
        padding-#{$direction}: #{$size / 10}rem;
    }
}

.padding {
    @for $i from 1 through 100 {
        &-#{$i} {
            @include padding('all', $i);
        }
        &-top-#{$i} {
            @include padding('top', $i);
        }
    
        &-bottom-#{$i} {
            @include padding('bottom', $i);
        }
    
        &-left-#{$i} {
            @include padding('left', $i);
        }
    
        &-right-#{$i} {
            @include padding('right', $i);
        }
    }
}
@mixin margin($direction, $size) {
    @if $direction == 'all' {
        margin: #{$size / 10}rem !important;
    } @else {
        margin-#{$direction}: #{$size / 10}rem !important;
    }
}

.margin {
    @for $i from 1 through 100 {
        &-#{$i} {
            @include margin('all', $i);
        }
        &-top-#{$i} {
            @include margin('top', $i);
        }
    
        &-bottom-#{$i} {
            @include margin('bottom', $i);
        }
    
        &-left-#{$i} {
            @include margin('left', $i);
        }
    
        &-right-#{$i} {
            @include margin('right', $i);
        }
    }
}

@mixin borderRadius($br) {
    border-radius: #{$br}px !important;
}
.br{
    @for $i from 1 through 100 {
        &-#{$i} {
            @include borderRadius($i)
        }
    }
}
@mixin gap($gap) {
    gap: #{$gap / 10}rem;
}
.gap{
    @for $i from 1 through 100 {
        &-#{$i} {
            @include gap($i)
        }
    }
}
.container{
    &.border{
        @include themify($themes){
            border: 1px solid themed('decorativeSubdued');
        }
    }
}
.form{
    .form-layout{
        width: 100%;
        display: flex;
        flex-flow: column;
        gap: 1.5rem;
        &.loading{
            opacity: .5;
            pointer-events: none;
        }
        .form-background{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
    &.send{
        padding: 1rem;
    }
    &.auth{
        min-height: calc(100vh - 4rem);
        padding-block: 2rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        .form-layout{
            max-width: 500px;
            width: 100%;
            border-radius: 5px;
            position: relative;
            overflow: hidden;
            @include themify($themes){
                border: 1px solid themed('decorativeSubdued');
            }
            .avatar-layout{
                display: flex;
                justify-content: center;
                margin-top: -.5rem;
                position: relative;
                padding-bottom: 2rem;
                input{
                    opacity: 0;
                    position: absolute;
                    visibility: hidden;
                }
                .avatar-body{
                    position: absolute;
                    bottom: 0;
                }
            }
            .form-error{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                text-align: center;
                background: v.$color-red;
                padding: .5rem;
                color: v.$dark-text-base;
            }
            .form-background{
                width: 100%;
                height: 0;
                overflow: hidden;
                padding-bottom: 8rem;
                @include themify($themes){
                    background: themed('bgHighlight');
                }
                img{
                    width: 100%;
                }
            }
            .form-seo{
                display: flex;
                flex-flow: column;
                gap: .5rem;
                align-items: center;
                justify-content: center;
                margin-bottom: -1rem;
                h1{
                    font-family: $font-branch;
                    font-weight: 400;
                    font-size: 1.75rem;
                    text-transform: uppercase;
                }
            }
            .form-body{
                display: flex;
                flex-flow: column;
                gap: 1.5rem;
                padding: 0 1rem 1rem;
            }
        }
    }
}
.MuiListSubheader-root{
    @include themify($themes){
        color: themed('textBase');
        background: themed('bgBase') !important;
    }
}
input.input,
.input{
    .MuiInputBase-root{
        @include themify($themes){
            color: themed('textBase');
            background: themed('bgBase');
        }
    }
    input{
        font-family: v.$font-primary;
        @include themify($themes){
            color: themed('textBase');
        }
    }
    input::placeholder{
        @include themify($themes){
            color: themed('textBase');
        }
    }
    fieldset{
        @include themify($themes){
            border-color: themed('decorativeSubdued');
        }
    }
    label{
        font-family: v.$font-primary;
        @include themify($themes){
            color: themed('textSubdued');
        }
        &.Mui-error{
            color: v.$color-red !important;
        }
    }
    &.success{
        fieldset{
            border-color: v.$color-green-l !important;
        }
    }
    .Mui-focused{
        fieldset{
            @include themify($themes){
                border-color: themed('decorativeSubdued');
            }
        }
    }
    
    p{
        font-family: v.$font-primary;
        @include themify($themes){
            color: themed('textSubdued');
        }
    }

    .MuiInputBase-root{
        &:hover{
            @include themify($themes){
                background: themed('bgHighlight');
            }
            fieldset{
                @include themify($themes){
                    border-color: themed('decorativeHighlight');
                }
            }
        }
        &.Mui-error{
            fieldset{
                border-color: v.$color-red !important;
            }
        }
    }
    /* ///// */
    &.date-picker{
        width: 100%;
        color: currentColor;
        div,
        button {
            color: currentColor;
            svg{
                color: currentColor;
            }
        }
    }
}
.MuiDateCalendar-root{
    .MuiTypography-root{
        @include themify($themes){
            color: themed('textBase');
        }
    }
    button{
        @include themify($themes){
            color: themed('textSubdued');
        }
        &.Mui-selected{
            color: #FFF;
            background: v.$color-branch !important;
        }
        &:hover{
            color: #FFF;
            background: v.$color-branch-dark !important;
        }
    }
}
.input-layout{
    display: flex;
    flex-flow: column;
    gap: .3rem;
    flex: 1;
    label{
        font-size: 1rem;
        @include themify($themes){
            color: themed('textSubdued');
        }
    }
    .input-icon{
        fill: currentColor;
        color: currentColor;
        width: 1.5rem !important; 
        height: 1.5rem !important;
        position: absolute;
        right: 0;
    }
    .input-body{
        display: flex;
        position: relative;
        align-items: center;
        button,a{
            position: absolute;
            right: 0;
            width: auto !important;
        }
    }
}
.input{
    font-family: $font-primary;
    padding: .5rem;
    border-radius: 3px;
    font-size: 1rem;
    border: 1px solid transparent;
    outline: 2px solid transparent;
    transition: .15s ease all;
    flex: 1;
    &::placeholder{
        @include themify($themes){
            color: themed('textSubdued');
        }
    }
    &.text-area{
        resize: none;
        @include themify($themes){
            border-color: themed('decorativeSubdued');
        }
    }
    &.password,
    &.with-icon{
        padding-right: 2.5rem;
    }
    &.variant{
        &-1{
            .MuiInputBase-root{
                @include themify($themes){
                    border-color: themed('decorativeSubdued');
                }
                &:hover{
                    @include themify($themes){
                        background: themed('bgElevatedHighlight');
                    }
                }
                &:focus{
                    @include themify($themes){
                        background: themed('bgBase');
                        outline-color: themed('bgElevatedHighlight');
                    }
                }
            }
        }
        &-2{
        }
    }
}
.select{
    @include themify($themes){
        background: themed('bgHighlight');
        color: themed('textBase');
    }
    font-family: v.$font-primary !important;
    > svg{
        color: currentColor;
    }
    fieldset{
        @include themify($themes){
            border-color: themed('decorativeSubdued') !important;
        }
    }
    &:hover{
        @include themify($themes){
            background: themed('bgElevatedBase');
        }
    }
}
.MuiPaper-root{
    @include themify($themes){
        background: themed('bgHighlight');
        color: themed('textBase');
    }
}
.flex{
    display: flex;
    &.flow{
        &-column{
            flex-flow: column;
        }
        &-row{
            flex-flow: row;
        }
    }
    &.gap{
        &-normal{
            gap: .5rem;
        }
        &-big{
            gap: 1rem;
        }
        &-small{
            gap: .25rem;
        }
        &-bigger{
            gap: 1.5rem;
        }
    }
    &.justify{
        &-center{
            justify-content: center;
        }
        &-end{
            justify-content: flex-end;
        }
        &-between{
            justify-content: space-between;
        }
    }
    &.align{
        &-center{
            align-items: center;
        }
        &-end{
            align-items: flex-end;
        }
    }
}
.font{
    &.title{
        &-small{
            font-size: .975rem;
            letter-spacing: .5px;
        }
        &-normal{
            font-size: 1.25rem;
        }
    }
    &.p{
        &-small{
            font-size: .875rem;
            @include themify($themes){
                color: themed('textSubdued') !important;
            }
            &.variant{
                &-negative{
                    font-weight: 500;
                }
            }
        }
    }
    &.variant{
        &-negative{
            color: v.$dark-text-negative;
        }
    }
    &.align{
        &-center{
            text-align: center;
        }
    }
    span {
        font-size: inherit;
        color: inherit;
    }
}
.font-style{
    &.color{
        &-negative{
            color: v.$dark-text-negative !important;
        }
        &-info{
            color: v.$color-branch-light !important;
        }
        &-positive{
            color: v.$dark-text-positive !important;
        }
        &-base{
            @include themify($themes){
                color: themed('textBase') !important;
            }
        }
        &-subdued{
            @include themify($themes){
                color: themed('textSubdued') !important;
            }
        }
    }
    &.uppercase{
        text-transform: uppercase;
        font-family: v.$font-branch;
        font-weight: 700;
    }
    &.small{
        font-size: .765rem;
        @include themify($themes){
            color: themed('textSubdued');
        }
    }
    &.medium{
        font-size: .9rem;
    }
    &.normal{
        font-size: 1rem;
    }
    &.big {
        font-size: 1.25rem;
    }
    .progress{
        display: flex;
        font-size: 1rem;
        color: currentColor;
        padding: 0;
        width: 1rem !important;
        height: 1rem !important;
    }
}
.MuiTooltip-tooltip{
    font-size: .8rem !important;
    margin-top: 0 !important;
    border-radius: 2px !important;
    background: v.$dark-bg-upper-highlight !important;
}
.hide{
    visibility: hidden;
    opacity: 0;
    position: absolute;
}
.ptr,
.ptr__children{
    overflow: visible !important;
    height: auto !important;
}
.pull-to-refresh{
    width: 100%;
    display: flex;
    height: 3.5rem;
    justify-content: center;
    align-items: center;
    > *{
        @include themify($themes){
            color: themed('textBase');
        }
    }
    &.body{
        height: 5rem;
    }
}
.lf-menu{
    > .MuiPaper-root{
        width: 100%;
        max-width: 13.5rem;
        border-radius: .5rem;
        overflow: hidden;
        @include themify($themes){
            background: themed('bgBase');
            border: 1px solid themed('decorativeSubdued');
        }
        .MuiList-root{
            padding: 0;
        }
        .button{
            width: 100%;
            padding: .5rem;
            &-icon{
                svg{
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
            &-text{
                flex: 1;
                justify-content: flex-start;
                display: flex;
                .title{
                    line-height: 1;
                    display: -webkit-box;
                    text-align: start;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    @include themify($themes){
                        color: themed('textBaseHighlight');
                    }
                }
            }
        }
    }
}
.font-branch{
    font-family: $font-branch;
    letter-spacing: .5px;
    &.text{
        letter-spacing: initial;
        font-weight: 500;
    }
    &.title{
        font-weight: 600;
    }
    *{
        font-family: $font-branch;
        font-weight: 600;
    }
}
.tags-and-rating{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: .25rem;
    .rating{
        color: v.$dark-text-positive !important;
        border: none !important;
        padding: 0;
        &.Rx,
        &.R\+{
            color: v.$dark-text-negative !important;
        }
        &.R-17\+{
            color: v.$color-orange !important;
        }
    }
    span{
        font-size: .875rem;
        padding-right: .3rem;
        line-height: 1;
        border-right: 1px solid currentColor;
        @include themify($themes){
            color: themed('textSubdued');
        }
        &:nth-last-child(1){
            border: none;
        }
    }
}
.ads-horizontal{
    display: flex;
    flex-flow: column;
    position: relative;
    margin: 0 auto;
    max-width: 728px;
    width: 100%;
    height: 90px;
    .ad-title{
        position: absolute;
        background: v.$color-yellow;
        padding: .25rem 1rem;
        text-transform: uppercase;
        font-weight: 700;
        color: v.$light-text-base;
        z-index: 1;
    }
    .ad-body{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        z-index: 0;
        @include themify($themes){
            background: themed('bgHighlight');
        }
        .no-ad{
            position: absolute;
        }
        .ad{
            position: absolute;
        }
    }
}
.comments{
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin: 0 -.5rem;
    position: relative;
    color-scheme: initial;
    a{
        @include themify($themes){
            color: themed('textBase');
        }
    }
    div{
        @include themify($themes){
            background: themed('bgBase');
        }
    }
    .comment-action{
        display: flex;
        flex-flow: column;
        width: 100%;
        border-radius: 5px;
        @include themify($themes){
            background: themed('bgBase');
        }
        > div{
            padding: 1rem;
        }
    }
}
.lazy-load{
    pointer-events: none;
}

@media (max-width: 50em) {
    .form{
        &.auth{
            height: auto;
            .form-layout{
                max-width: initial;
                border-radius: 0;
                border: none !important;
                .form-background{
                    padding-bottom: 12rem;
                    span{
                        width: 100%;
                    }
                }
            }
        }
    }
}