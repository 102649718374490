@use '../../styles/variables' as v;
@import '../../styles/theme-variables.scss';
.browse-card{
    --card-padding: .25rem;
    min-width: 0;
    position: relative;
    padding: var(--card-padding);
    cursor: pointer;
    border-radius: .5rem;
    border: 1px solid transparent;
    &:hover{
        @include themify($themes){
            border-color: themed('decorativeSubdued');
            background: themed('bgHighlight');
        }
    }
    &.lazy{
        pointer-events: none;
        .browse-card__title{
            height: 1.38rem;
            border-radius: 100px;
            @include themify($themes){
                background: themed('bgElevatedHighlight');
            }
        }
    }
    &.wide{
        .browse-card__poster{
            .figure-wrapper{
                aspect-ratio: 16 / 9;
                .figure.serie{
                    opacity: 0;
                    visibility: hidden;
                    transition: .2s;
                }
                .watched{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: #00000085;
                    color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        font-size: 10vmin;
                    }
                    &.partial{
                        background: #00000085;
                        svg{
                            background: #000000a8;
                            border-radius: 100px;
                            padding: .25rem;
                            font-size: 8vmin;
                        }
                        .border{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            background: #000000a8;
                            height: .4rem;
                            &::before{
                                content: "";
                                display: block;
                                width: 63%;
                                height: 100%;
                                background: v.$color-branch-light;
                            }
                        }
                    }
                }
            }
        }
        &:hover{
            .browse-card__poster{
                .figure-wrapper{
                    .figure.serie{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .hover-link{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }
    &__poster-wrapper{
        display: block;
        position: relative;
    }
    &__poster{
        --content-image-ar-priority-width: 1;
        --width: calc(100%*var(--content-image-ar-priority-width)/var(--content-image-ar-priority-width));
        --height: calc(100%*var(--content-image-ar-priority-height)/var(--content-image-ar-priority-height));
        width: var(--width);
        height: var(--height);
        position: relative;
        .figure-wrapper{
            border-radius: .25rem;
            height: var(--height);
            width: var(--width);
            aspect-ratio: 2 / 3;
            display: flex;
            @include themify($themes){
                background: themed('bgElevatedHighlight');
            }
            .tag{
                position: absolute;
                z-index: 1;
                top: -.5rem;
                min-width: 50%;
                text-transform: uppercase;
                left: -.25rem;
                text-align: center;
                padding: .25rem .5rem;
                font-weight: 600;
                @include themify($themes){
                    color: themed('adText');
                    background: themed('adBackground');
                }
            }
            &__figure-sizer{
                display: inline-flex;
                height: inherit;
                position: relative;
                width: inherit;
                .figure{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    img{
                        display: block;
                        height: 100%;
                        object-fit: cover;
                        object-position: unset;
                        width: 100%;
                        transition: .3s;
                    }
                }
            }
        }
    }
    &__body{
        padding-top: .75rem;
        position: relative;
        .browse-card{
            &__serie-name{
                text-transform: uppercase;
                font-size: .8rem;
                font-weight: bold;
                font-family: 'Montserrat', sans-serif;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                @include themify($themes){
                    color: themed('textSubdued');
                }
                &:hover{
                    text-decoration: underline;
                    @include themify($themes){
                        color: themed('textBase');
                    }
                }
            }
            &__title{
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                white-space: unset;
            }
            &__title-link{
                text-decoration: none;
                font-weight: 500;
                @include themify($themes){
                    color: themed('textBase');
                }
            }
            &__footer{
                position: relative;
                .tags-and-rating{
                    margin-top: .5rem;
                    max-width: 100%;
                }
            }
        }
    }
}

@media (min-width: 30em) {
    .browse-card{
        --card-padding: .25rem;
    }
}

@media (min-width: 35.5em) {
    .browse-card{
        --card-padding: .5rem;
    }
}

@media (min-width: 50em) {
    .home-content{
        .section-layout{
            --tall-cards-carousel-cols: 3.5;
        }
    }
}

@media (min-width: 64em) {
    .home-content{
        .section-layout{
            --tall-cards-carousel-cols: 4;
        }
    }
}

@media (min-width: 70em) {
    .browse-card{
        --card-padding: .75rem;
    }
}

@media (min-width: 80em) {
    .home-content{
        .section-layout{
            --tall-cards-carousel-cols: 5;
        }
    }
}

@media (min-width: 90em) {
    .home-content{
        .section-layout{
            --tall-cards-carousel-cols: 6;
        }
    }
}