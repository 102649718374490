$font-branch: 'Montserrat', sans-serif;
$font-logo: "Urbanist", sans-serif;
$font-primary: "Roboto", sans-serif;

$color-branch: #4169e1;
$color-branch-dark: #2e4ba1;
$color-branch-light: #6a8fff;

$color-black: #000;
$color-red: #c0392b;
$color-red-d: #81261d;
$color-red-txt: #ff5441;
$color-green: #0f672e;
$color-green-l: #159442;
$color-yellow: #F1C40F;
$color-orange: #F57F17;

$dark-bg-base: #121212;
$dark-bg-header: #121212e6;
$dark-bg-base-tranparent: #12121233;
$dark-bg-highlight: #1a1a1a;
$dark-bg-press: #000;
$dark-bg-elevated-base: #242424;
$dark-bg-elevated-highlight: #2a2a2a;
$dark-bg-elevated-press: #000;
$dark-bg-upper-base: #494949;
$dark-bg-upper-highlight: #6d6d6d;
$dark-bg-upper-press: #000;
$dark-text-base: #fff;
$dark-text-subdued: #a7a7a7;
$dark-text-highlight: #e2e2e2;
$dark-text-base-highlight: #d9d9d9;
$dark-text-negative: #f15e6c;
$dark-text-positive: #1ed760;
$dark-decorative-base: #fff;
$dark-decorative-subdued: #292929;
$dark-decorative-highlight: #393939;
$dark-decorative-highlight-press: #dadada;

$light-bg-base: #fff;
$light-bg-header: #FFFFFFe6;
$light-bg-base-tranparent: #ffffffde;
$light-bg-highlight: #f1f1f1;
$light-bg-press: #FFF;
$light-bg-elevated-base: #ececec;
$light-bg-elevated-highlight: #d9d9d9;
$light-bg-elevated-press: #FFF;
$light-bg-upper-base: #d1d1d1;
$light-bg-upper-highlight: #d9d9d9;
$light-bg-upper-press: #FFF;
$light-text-base: #000;
$light-text-subdued: #5a5a5a;
$light-text-highlight: #121212;
$light-text-base-highlight: #6a6a6a;
$light-text-negative: #f15e6c;
$light-text-positive: #1ed760;
$light-decorative-base: #000;
$light-decorative-subdued: #dadada;
$light-decorative-highlight: #393939;
$light-decorative-highlight-press: #dadada;